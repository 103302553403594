import axios, {AxiosError, AxiosRequestConfig} from 'axios';
import {get, set} from 'lodash';
import qs from 'qs';

// Stores the state of already redirected login page
let isRedirectedLogin: boolean = false;

/**
 * Add API token (if exists) to request header before it is sent to the API.
 */
const authInterceptor = async(config: AxiosRequestConfig) => {
    // For some unknown reason retrieving the api token from store state causes
    // a "super expression must either be null or a function" TypeError in the
    // browser. The code responsible for this is
    const {default: store} = await import('@/store');

    const apiToken = get(config, 'headers.Authorization', `Bearer ${store.state.auth.apiToken}`);

    if (apiToken) {
        set(config, 'headers.Authorization', apiToken);
    }

    return config;
};

const errorInterceptor = async(error: AxiosError) => {
    if (error.response?.status === 401 && error.response?.config.url !== 'auth/login') {
        // If already redirected, cancel all upcoming sync request
        if (isRedirectedLogin) {
            throw new axios.Cancel();
        }

        isRedirectedLogin = true;

        const {default: store} = await import('@/store');

        await store.dispatch('auth/logout');
    }

    return Promise.reject(error);
};

/**
 * A function to create an axios instance that will be used as http client.
 */
export default function createHttpClient(baseURL:string = process.env.VUE_APP_API_URL!) {
    const httpClient = axios.create({
        baseURL,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        paramsSerializer: qs.stringify,
    });

    httpClient.interceptors.request.use(authInterceptor);
    httpClient.interceptors.response.use(undefined, errorInterceptor);

    return httpClient;
};
