import {AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios';
import BaseRequest from 'vue-mc/src/HTTP/Request';
import Response from './Response';
import createHttpClient from './createHttpClient';

export default class Request extends BaseRequest {
    private httpClient: AxiosInstance;

    /**
     * Construct Request instance with http client that uses the given base URL.
     */
    constructor(config: AxiosRequestConfig, baseURL: string = process.env.VUE_APP_API_URL!) {
        super(config);

        this.httpClient = createHttpClient(baseURL);
    }

    /**
     * Overrides vue-mc to use our own Response class.
     */
    createResponse(axiosResponse?: AxiosResponse): Response {
        return new Response(axiosResponse, this.config);
    }

    /**
     * Overrides vue-mc's Request to use our own HTTP client.
     *
     * @returns {Promise}
     */
    send(): Promise<Response> {
        return this.httpClient
            .request(this.config)
            .then(this.createResponse.bind(this))
            .catch((error) => {
                throw this.createError(error);
            });
    }
}
