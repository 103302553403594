import {NavigationGuard} from 'vue-router';
import store from '@/store';

const permission: NavigationGuard = (to, from, next) => {
    const me = store.state.auth.me;

    if (!me || !to.matched.every(route => me.canAny(route.meta.permissions))) {
        next({name: 'home'});

        return false;
    }

    return true;
};

export default permission;
