import {RouteConfig} from 'vue-router';
import {applySidebarParent} from '@/router/helpers';

const routes: RouteConfig[] = [
    {
        path: '/profile',
        component: () => import('@/pages/profile/Layout.vue'),
        children: [
            {
                name: 'profile.index',
                path: '',
                component: () => import('@/pages/profile/Index.vue'),
            },
            {
                name: 'profile.company',
                path: 'company',
                component: () => import('@/pages/profile/Company.vue'),
                meta: {
                    permissions: ['read:companies', 'read:users:companies'],
                },
            },
            {
                name: 'profile.team',
                path: 'team',
                component: () => import('@/pages/profile/Team.vue'),
                meta: {
                    permissions: ['read:users', 'read:companies:users'],
                },
            },
        ],
    },
];

applySidebarParent('profile.index', routes);

export default routes;
