import Enumeration, {Enum} from '@/library/enumerations/Enumeration';

@Enum
export default class Role extends Enumeration {
    static translationKey = 'roles';

    static STEEL_SOLUTIONS_ADMIN = 1;
    static COMPANY_ADMIN = 2;
    static FINANCIAL_OFFICER = 3;
    static CONTRACTOR = 4;
    static STEEL_SOLUTIONS_EMPLOYEE = 5;
}
