import {debounce, result, set} from 'lodash';
import {DirectiveBinding} from 'vue/types/options';
import {VNode} from 'vue';
let closeHandler: any;
let clickHandler: any;
let shadow: any;

export default {
    bind: (el: Element, binding: DirectiveBinding, vnode: VNode) => {
        // Wait until Vue's next tick to bind correct time
        vnode.context?.$nextTick(() => {
            // Get InputDdSearch component
            const context = vnode.context?.$children.find(item => item.$options.name === 'InputDdSearch');

            if (!context) {
                throw Error('Searchable can only use under the InputDdSearch');
            }

            context.$root.$on('bv::dropdown::show', () => {
                set(context, 'show', false);
            });

            clickHandler = () => {
                // Original DOM element cloned because Vue try to re-render component when content edited
                shadow = el.cloneNode(true);

                el.classList.remove('d-block');
                el.classList.add('d-none');

                shadow.innerText = '';

                if (vnode.elm) {
                    (vnode.elm.parentElement as HTMLInputElement).appendChild(shadow);
                }

                shadow.focus();

                closeHandler = () => {
                    set(context, 'show', false);

                    shadow.remove();

                    el.classList.remove('d-none');
                    el.classList.add('d-block');
                };

                document.addEventListener('click', closeHandler);

                shadow.addEventListener('input', debounce(async() => {
                    // changing search query InputDdSearch and fetching elements
                    set(context, 'collection.searchQuery', shadow.innerText);
                    await result(context, 'refetchItems');
                }, 250));

                shadow.addEventListener('blur', () => {
                    // Copied element removed and original element restored
                    shadow.remove();

                    el.classList.remove('d-none');
                    el.classList.add('d-block');
                });
            };

            // Gives "editable" ability any element
            el.classList.add('d-block', 'cursor-text', 'outline-0', 'text-break');
            el.setAttribute('contenteditable', '');
            el.addEventListener('click', clickHandler);
        });
    },
    unbind: (el: Element) => {
        try {
            // Removing all listeners and to make sure 100% copied element removed
            el.removeEventListener('click', clickHandler);
            document.removeEventListener('click', closeHandler);

            shadow.remove();

            el.classList.remove('d-none');
            el.classList.add('d-block');
        } catch {}
    },
};
