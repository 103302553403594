import {NavigationGuard} from 'vue-router';
import store from '@/store';

export const REDIRECT_BACK_KEY = 'auth-redirect-back';

const auth: NavigationGuard = (to, from, next) => {
    if (!store.state.auth.me) {
        sessionStorage.setItem(REDIRECT_BACK_KEY, to.fullPath);

        next({name: 'auth.login'});

        return false;
    }

    // If logged-in user is steel solutions admin we should
    // not let them continue on portal because they don't have
    // any company, so we redirect them to admin panel
    if (store.state.auth.me.isSteelSolutionsUser) {
        localStorage.clear();

        location.href = process.env.VUE_APP_API_ADMIN_URL as string;

        return false;
    }

    sessionStorage.removeItem(REDIRECT_BACK_KEY);

    return true;
};

export default auth;
