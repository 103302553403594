import {length, required} from 'vue-mc/src/Validation';
import Collection from '@/models/vue-mc/cms/Collection';
import CompanyType from '@/library/enumerations/CompanyType';
import EmployeeAmount from '@/library/enumerations/EmployeeAmount';
import Model from '@/models/vue-mc/cms/Model';
import {stringToVariant} from '@/library/helpers';

class Company extends Model {
    get companyTypeEnum(): CompanyType {
        return CompanyType.object(this.companyType);
    }

    get employeeAmountEnum(): EmployeeAmount {
        return EmployeeAmount.object(this.employeeAmount);
    }

    get initial(): String {
        return this.name ? this.name[0].toUpperCase() : '';
    }

    get variant(): BVariant {
        return stringToVariant(this.$.name);
    }

    defaults(): object {
        return {
            id: null,
            name: null,
            vatNumber: null,
            ccNumber: null,
            companyType: null,
            employeeAmount: null,
            // additionals
            logo: null,
        };
    }

    options(): object {
        return {
            endpoint: 'companies',
        };
    }

    validation(): Record<string, any> {
        return {
            name: required.and(length(1, 50)),
            vatNumber: length(0, 50),
            ccNumber: length(0, 50),
        };
    }
}

class Companies extends Collection {
    options() {
        return {
            model: Company,
        };
    }
}

export default Company;

export {Company, Companies};
