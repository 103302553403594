



















































import Collection from '@/models/vue-mc/Collection';
import Model from '@/models/vue-mc/Model';
import Vue from 'vue';

export default Vue.extend({
    props: {
        noModelsMessage: {
            type: String,
            default: '',
        },
        resource: {
            type: [Collection, Model],
            required: true,
        },
        showErrorMessage: {
            type: Boolean,
            default: true,
        },
    },

    computed: {
        showModelsMessage(): boolean {
            return this.resource.models
                && !this.resource.loading
                && !this.resource.models.length;
        },

        hasModelsMessage(): boolean {
            return !!this.$slots['no-models-message']
                || !!this.noModelsMessage;
        },
    },
});
