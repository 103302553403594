import {get} from 'lodash';
import jwt_decode from 'jwt-decode';
import store from '@/store';

export default class ApiToken {
    encoded?: string;
    decoded?: Object;

    constructor(token?: string) {
        this.encoded = token || store.state.auth.apiToken as string;

        this.decoded = jwt_decode<Object>(this.encoded);
    }

    get userId(): number | null {
        // sub is shorten for Subject (whom the token refers to)
        return get(this.decoded, 'sub', null);
    }
}
