const RESERVED = [
    '_attributes',
    '_collections',
    '_errors',
    '_listeners',
    '_reference',
    '_registry',
    '_uid',
    'attributes',
    'collections',
    'deleting',
    'errors',
    'fatal',
    'loading',
    'memoized',
    'models',
    'saving',
];

export default abstract class RegistersAttributes {
    abstract get(attribute: string, fallback?: any): any;

    abstract set<T = any>(attribute: string | Record<string, any>, value?: T): T | undefined;

    /**
     * Registers an attribute on this resource so that it can be accessed
     * directly, passing through `get` and `set`.
     */
    registerAttribute(attribute: string): void {
        // Protect against unwillingly using an attribute name that already
        // exists as an internal property or method name.
        if (RESERVED.includes(attribute)) {
            throw new Error(`Can't use reserved attribute name '${attribute}'`);
        }

        const descriptor = Object.getOwnPropertyDescriptor(this.constructor.prototype, attribute) || {};

        // Do not override custom getters and setters defined in the resource.
        if (!descriptor.get) {
            descriptor.get = (): any => this.get(attribute);
        }
        if (!descriptor.set) {
            descriptor.set = <T>(value: T): T | undefined => this.set(attribute, value);
        }

        // Register getter and setter so that we can update the resource directly
        // while also keeping the `_attributes` in sync.
        Object.defineProperty(this, attribute, descriptor);
    }
}
