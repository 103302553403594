import Collection from '@/models/vue-mc/cms/Collection';
import Company from '@/models/Company';
import HasRoles from '@/models/mixins/HasRoles';
import Model from '@/models/vue-mc/cms/Model';
import {Roles} from '@/models/Role';
import {applyMixins} from '@/library/helpers';

class User extends Model {
    /**
     * Default attributes that define the "empty" state.
     */
    defaults(): object {
        return {
            id: null,
            firstName: '',
            lastName: '',
            fullName: '',
            email: '',
            department: '',
            mobileNumber: '',
            workNumber: '',
            company: null,
            profilePicture: {},
            roles: [],
            permissions: [],
        };
    }

    /**
     * Options of this model.
     */
    options(): object {
        return {
            endpoint: 'users',
            methods: {
                patch: 'PUT',
            },
        };
    }

    /**
     * Mapping of model attributes to the Model or Collection class
     * it should be transformed into.
     */
    transformations() {
        return {
            company: Company,
            roles: Roles,
        };
    }

    /**
     * Checking permissions of user to have every permission.
     */
    can(permissions: any): boolean {
        return !permissions || ([] as string[])
            .concat(permissions)
            .every(p => this.permissions.includes(p));
    }

    /**
     * Checking permissions of user to have any permission.
     */
    canAny(permissions: any): boolean {
        return !permissions || ([] as string[])
            .concat(permissions)
            .some(p => this.permissions.includes(p));
    }
}

class Users extends Collection {
    options() {
        return {
            model: User,
        };
    }
}

applyMixins(User, [HasRoles]);

export default User;

export {User, Users};
