import {AxiosRequestConfig, AxiosResponse} from 'axios';
import {get, has, values} from 'lodash';
import BaseResponse from 'vue-mc/src/HTTP/Response';

export default class Response extends BaseResponse {

    readonly request?: AxiosRequestConfig;

    constructor(response?: AxiosResponse, request?: AxiosRequestConfig) {
        super(response);

        this.request = request;
    }
    /**
     * Overrides vue-mc's Response to be able to handle resource from API.
     */
    getData(): any | null {
        // Handle paginated response by checking if `currentPage` attribute exists.
        if (
            has(this.response, 'data.currentPage')
            && has(this.response, 'data.data')
        ) {
            const data = get(this.response, 'data.data', null);
            // For unknown reason, the paginated data returned from API is not an
            // array but an object. We need to transform this object into
            // an array by using `_.values`.
            return values(data);
        }

        return get(this.response, 'data', null);
    }

    /**
     * Overrides vue-mc's Response to return our API validation errors.
     */
    getValidationErrors(): Record<string, any> {
        // Also return the response's error message.
        const message = this.getErrorMessage();

        return {
            message,
            ...get(this.response, 'data.errors'),
        };
    }

    /**
     * Returns the error message contained by the response.
     */
    getErrorMessage(): string {
        if (!this.response) {
            return 'Application failed to respond!';
        }

        return get(
            this.response,
            'data.message',
            this.response.statusText,
        );
    }
}
