import {RequestOperation, RequestOptions} from 'vue-mc/src/Structures/Base';
import axios, {AxiosRequestConfig, Canceler} from 'axios';
import {
    defaultTo,
    defaults,
    each,
    every,
    find,
    get,
    has,
    isEmpty,
    isObject,
    isPlainObject,
    set,
    some,
} from 'lodash';
import BaseCollection from 'vue-mc/src/Structures/Collection';
import Model from './Model';
import RegistersAttributes from './mixins/RegistersAttributes';
import Request from './Request';
import RequestError from 'vue-mc/src/Errors/RequestError';
import Response from './Response';
import Vue from 'vue';
import {applyMixins} from '@/library/helpers';

/**
 * Used as a marker to indicate that a collection has paged through all results.
 */
const LAST_PAGE = 0;

interface Collection extends RegistersAttributes {
    searchQuery: string;
    sortBy: string;
    sortDesc: boolean;
    filters: Record<string, any>;
}

class Collection extends BaseCollection {
    protected baseUrl?: string;
    protected perPage?: number;

    private readonly fatalErrorMessage!: string;

    private _canceler?: Canceler;

    defaults(): Record<string, any> {
        return {
            // These attributes are used to build the fetch query (see `getFetchQuery`).
            searchQuery: '',
            perPage: null,
            sortBy: '',
            sortDesc: false,
        };
    }

    get errors(): Record<string, string | string[]>[] {
        return this.getErrors();
    }

    get errorMessage(): string {
        return this.fatalErrorMessage || '';
    }

    /**
     * @returns {boolean} `true` if this collection has errors, `false` otherwise.
     */
    get hasErrors(): boolean {
        return this.getErrors().some(errors => !isEmpty(errors));
    }

    get firstErrorMessage(): string {
        return this.first()?.firstErrorMessage || '';
    }

    /**
     * Overrides vue-mc's Collection to use our own Request class.
     *
     * @returns {Request} A new `Request` using the given configuration.
     */
    createRequest(config: AxiosRequestConfig): Request {
        return new Request(defaults(config, {
            cancelToken: new axios.CancelToken((cancelFn) => {
                Vue.set(this, '_canceler', cancelFn);
            }),
        }), this.baseUrl);
    }

    /**
     * Overrides vue-mc to use our own Model class.
     *
     * @return {Model} The class/constructor for this collection's model type.
     */
    model(): typeof Model {
        return this.getOption('model');
    }

    models!: Model[];

    /**
     * Sets `_attributes` of this collection.
     */
    set<T = any>(attribute: string | Record<string, any>, value?: T): T | undefined {
        if (isPlainObject(attribute)) {
            each(attribute as Record<string, any>, (value, key): void => {
                this.set(key, value);
            });

            return;
        }

        const registered: boolean = this.hasAttribute(attribute as string);

        // Register getter and setter for the attribute.
        if (!registered) {
            this.registerAttribute(attribute as string);
        }

        Vue.set(this.getAttributes(), attribute as string, value);

        return value;
    }

    /**
     * Tries to construct our API route based on endpoint of this collection's model.
     * It's possible to prefix the route with some string, just set `routePrefix` in
     * the collection's `options()`.
     *
     * @return {string|undefined} Route value by key.
     */
    getRoute(key: string, fallback?: string): string {
        let route;

        try {
            route = super.getRoute(key, fallback);
        } catch (error) {
            // If this part is reached, that means `routes()` do not contain specified key.
            // We'll construct the route based on the `options()`.

            route = this.getOption('endpoint') || this.model().endpoint;

            if (!route) {
                throw new Error('Collection\'s endpoint is not yet defined.');
            }

            const prefix = this.getOption('routePrefix');

            if (prefix) {
                route = `${prefix}/${route}`;
            }
        }

        return route;
    }

    /**
     * Determines whether a given value is an instance of a model.
     *
     * @param  {*} candidate A model candidate
     * @return {boolean} `true` if the given `model` is an instance of Model.
     */
    isModel(candidate: any): boolean {
        // Overwrite vue-mc to not check `_uid` as it gives error.
        return isObject(candidate)
            && has(candidate, '_attributes');
    }

    /**
     * Overrides vue-mc to also check on the model's identifier (typically `id`).
     *
     * @return {boolean} true if this collection has the model in its registry.
     */
    hasModelInRegistry(model: Model): boolean {
        const isDuplicate = Boolean(find(this.models, m => {
            return m.identifier() && m.identifier() === model.identifier();
        }));

        return isDuplicate || super.hasModelInRegistry(model);
    }

    /**
     * The models whose data will be sent to API when saving.
     */
    getSavingModels(): Model[] {
        // By default we will save all models in the collection. The main reason is because
        // if we don't send all models, when API returns error object, it won't be mapped
        // to the correct model.
        return this.models;
    }

    getSaveMethod() {
        // If the first model of the collection already has an id, make PATCH request.
        if (this.first() && this.first()?.isExisting()) {
            return this.getPatchMethod();
        }

        // Else, we make POST request.
        return this.getCreateMethod();
    }

    /**
     * @returns {Object} Query parameters that will be appended to the `fetch` URL.
     */
    getFetchQuery(): Record<string, any> {
        const query = super.getFetchQuery();

        if (this.searchQuery) {
            set(this, 'filters.search', this.searchQuery);
        } else if (this.filters?.search) {
            this.filters.search = undefined;
        }

        if (this.perPage) {
            query.perPage = this.perPage;
        }

        if (this.sortBy != null && this.sortBy != '') {
            // Set the attribute to sort on and the sorting direction.
            query.sort = this.sortDesc ? '-' + this.sortBy : this.sortBy;
        }

        if (this.filters) {
            query.filter = this.filters;
        }

        return query;
    }

    /**
     * This method is overriden just to add `data` to request config.
     * @param options
     */
    fetch(options: RequestOptions = {}): Promise<Response> {
        const config = (): AxiosRequestConfig => {
            return {
                url: defaultTo(options.url, this.getFetchURL()),
                method: defaultTo(options.method, this.getFetchMethod()),
                params: defaults(options.params, this.getFetchQuery()),
                headers: defaults(options.headers, this.getFetchHeaders()),
                // Add data to the config object.
                data: options.data,
            };
        };

        // @ts-ignore not sure why this is giving typescript errors.
        return this.request(
            config,
            this.onFetch,
            // @ts-ignore
            this.onFetchSuccess,
            this.onFetchFailure,
        );
    }

    /**
     * Called before a fetch request is made. Vue-mc forgets to check the loading state,
     * so we add it here.
     *
     * @returns {boolean|undefined} `false` if the request should not be made.
     */
    onFetch(): Promise<RequestOperation> {
        // Don't fetch if we're already busy fetching this collection.
        if (this.loading) {
            return Promise.resolve(RequestOperation.REQUEST_SKIP);
        }

        return super.onFetch();
    }

    /**
     * Responsible for adjusting the page and appending of models that were
     * received by a paginated fetch request.
     */
    applyPagination(models: Model[]): void {
        const oldSize = this.size();

        super.applyPagination(models);

        // If after `super.applyPagination()` the collection's size is unchanged,
        // that means API is returning duplicate paginated data.
        if (models.length && this.size() === oldSize) {
            // Set `_page` to last page to stop paginating.
            Vue.set(this, '_page', LAST_PAGE);

            throw new Error('API is returning duplicate paginated data.');
        }
    }

    /**
     * Called when a fetch request was successful.
     */
    onFetchSuccess(response: Response) {
        if (this.isPaginated()) {
            // Check for the `lastPage` attribute returned in the API paginated resource.
            const lastPage = get(response, 'response.data.lastPage', null);

            // If already at the last page, set `_page` to -1 to prevent further
            // unnecessary fetching. See `super.applyPagination()`.
            if (lastPage === this.getPage() || lastPage === 1) {
                Vue.set(this, '_page', LAST_PAGE - 1);
            }
        }

        super.onFetchSuccess(response);
    }

    /**
     * Called when a fetch request failed.
     */
    onFetchFailure(error: Error|RequestError): void {
        // Do not set fatal to true if the request is cancelled.
        if (error instanceof RequestError && !axios.isCancel(error.getError())) {
            super.onFetchFailure(error);

            Vue.set(this, 'fatalErrorMessage', (error.getResponse() as Response).getErrorMessage());
        }
    }

    /**
     * Sets validation errors on this collection's models.
     */
    setErrors(errors: any[] | Record<string, Record<string, string | string[]>>): void {
        if (isPlainObject(errors)) {
            // Vue-mc assumes that error object from API will be of type
            // `Record<string, Record<string, string | string[]>>` but this is incorrect
            // for our API. The correct type is `Record<string, string | string[]>`. We
            // customly handle this error object in `setErrorsToModels`.
            this.setErrorsToModels(errors as unknown as Record<string, string | string[]>);
        } else {
            super.setErrors(errors);
        }
    }

    /**
     * ========================================================================
     * Additional Methods
     * ========================================================================
     */

    /**
     * Cancel the last axios request that was made.
     */
    cancelRequest() {
        if (!this._canceler) {
            throw new Error('There is no request to be cancelled.');
        }

        this.clearState();

        this._canceler();

        Vue.set(this, '_canceler', undefined);
    }

    /**
     * Returns true if any model in this collection has changed.
     */
    changed(): boolean {
        return this.some((model: Model) => !!model.changed());
    }

    /**
     * Check if this collection has the given attribute.
     */
    hasAttribute(attribute: string): boolean {
        return has(this.getAttributes(), attribute);
    }

    /**
     * Add `index` to each model based on its order in this collection. The first
     * model will have index 0.
     */
    indexModels() {
        this.models.forEach((model, index) => {
            Vue.set(model, 'index', index);
        });
    }

    /**
     * Set the validation errors object returned by API in each corresponding
     * model's `_errors`.
     */
    setErrorsToModels(errors: Record<string, string | string[]>): void {
        this.clearErrors();

        // The structure of errors object from API is like this:
        // {'users.0.company.title': 'Title is required'}. We need to set each error
        // message to the corresponding model in the collection.
        each(errors, (message, key) => {
            // Split the error key using `.` as separator.
            const [, index, ...suffix] = key.split('.');

            // Join the last parts (suffix) with `.`, so we always have 3 parts in total.
            const attribute = suffix.join('.');

            const model: Model = get(this.models, index);

            if (model) {
                // Following the example above, this will set error for models[0]
                // with {'company.title': 'Title is required'}.
                model.setErrors({[attribute]: message});
            } else {
                this.models.forEach((model: Model) => model.setErrors({message}));
            }
        });
    }

    /**
     * Set direct property of this collection. E.g.: loading, saving, _page, etc.
     */
    setProperty(key: string, value: any) {
        Vue.set(this, key, value);
    }

    /**
     * Returns true if any model in this collection passes the predicate check, else false.
     *
     * @see {@link https://lodash.com/docs/#some}
     */
    some(predicate: string | Function | object): boolean {
        return some(this.models, predicate);
    }

    /**
     * Returns true if every model in this collection passes the predicate check, else false.
     *
     * @see {@link https://lodash.com/docs/#some}
     */
    every(predicate: string | Function | object): boolean {
        return every(this.models, predicate);
    }
}

applyMixins(Collection, [RegistersAttributes]);

export default Collection;
