import {NavigationGuardNext, Route, RouteConfig} from 'vue-router';
import {Vue} from 'vue/types/vue';
import guest from '@/router/middleware/guest';

/**
 * Clears the current user and apiToken by calling the action 'auth/logout' on the store.
 *
 * @see {@link Auth.logout}
 * @param to
 * @param from
 * @param next
 */
async function clearToken<V extends Vue>(this: Vue, to: Route, from: Route, next: NavigationGuardNext<V>) {
    try {
        await this.$store.dispatch('auth/logout', false);

        return true;
    } catch (e) {
        next({name: 'home'});

        return false;
    }
}

const routes: RouteConfig[] = [
    {
        path: '/auth',
        name: 'auth',
        component: () => import('@/components/layouts/Auth.vue'),
        children: [
            {
                path: 'login',
                name: 'auth.login',
                meta: {
                    middleware: [guest],
                },
                component: () => import('@/pages/auth/Login.vue'),
            },
            {
                path: 'logout',
                name: 'auth.logout',
                meta: {
                    middleware: [clearToken],
                },
                component: () => import('@/pages/auth/Logout.vue'),
            },
            {
                path: 'password/forgot',
                name: 'auth.password.forgot',
                meta: {
                    middleware: [guest],
                },
                component: () => import('@/pages/auth/password/Forgot.vue'),
            },
            {
                path: 'password/reset',
                name: 'auth.password.reset',
                meta: {
                    middleware: [clearToken],
                },
                component: () => import('@/pages/auth/password/Reset.vue'),
            },
            {
                path: 'register',
                name: 'auth.register',
                meta: {
                    middleware: [clearToken],
                },
                component: () => import('@/pages/auth/Register.vue'),
            },
            {
                path: 'impersonate',
                name: 'auth.impersonate',
                props: (route: Route) => ({
                    token: route.query.token,
                }),
                meta: {
                    middleware: [clearToken],
                },
                component: () => import('@/pages/auth/Impersonate.vue'),
            },
        ],
    },
];

export default routes;
