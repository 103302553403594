import {
    faAgreementGear,
    faArrowRight,
    faCheckCircle,
    faCheckCircleSlim,
    faCheckSlim,
    faChevronDoubleDown,
    faChevronDoubleRight,
    faChevronDoubleUp,
    faChevronTripleRight,
    faCog,
    faCross,
    faCube,
    faCursorInWindow,
    faDownload,
    faExclamationRhombus,
    faExclamationTriangle,
    faFilter,
    faHome,
    faInfoCircle,
    faInvoice,
    faLogoutSlim,
    faMenu,
    faOrderTransport,
    faPaperPlane,
    faPlus,
    faProduct,
    faTimesSlim,
    faTransfer,
} from '@/library/customIcons';
import {
    faBars,
    faBox,
    faBuilding,
    faCalendarAlt,
    faCheck,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faClock,
    faCoins,
    faEllipsisV,
    faEnvelope,
    faExchangeAlt,
    faExclamationCircle,
    faFileAlt,
    faLongArrowAltRight,
    faMapMarkerAlt,
    faMinus,
    faPencilAlt,
    faQuestion,
    faRoute,
    faSearch,
    faShip,
    faSignInAlt,
    faSignOutAlt,
    faSortDown,
    faTimes,
    faTrailer,
    faTrain,
    faTruck,
    faUser,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import Vue from 'vue';
import {library} from '@fortawesome/fontawesome-svg-core';

export default {
    init() {
        library.add(
            faAgreementGear,
            faArrowRight,
            faBars,
            faBox,
            faBuilding,
            faCalendarAlt,
            faCheck,
            faCheckCircle,
            faCheckCircleSlim,
            faCheckSlim,
            faChevronDoubleDown,
            faChevronDoubleRight,
            faChevronDoubleUp,
            faChevronDown,
            faChevronLeft,
            faChevronRight,
            faChevronTripleRight,
            faClock,
            faCog,
            faCoins,
            faCross,
            faCube,
            faCursorInWindow,
            faDownload,
            faEllipsisV,
            faEnvelope,
            faExchangeAlt,
            faExclamationCircle,
            faExclamationRhombus,
            faExclamationTriangle,
            faFileAlt,
            faFilter,
            faHome,
            faInfoCircle,
            faInvoice,
            faLogoutSlim,
            faLongArrowAltRight,
            faMapMarkerAlt,
            faMenu,
            faMinus,
            faOrderTransport,
            faPaperPlane,
            faPencilAlt,
            faPlus,
            faProduct,
            faQuestion,
            faRoute,
            faSearch,
            faShip,
            faSignInAlt,
            faSignOutAlt,
            faSortDown,
            faTimes,
            faTimesSlim,
            faTrailer,
            faTrain,
            faTransfer,
            faTruck,
            faUser,
        );

        Vue.component('Fa', FontAwesomeIcon);
    },
};
