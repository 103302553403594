import Enumeration, {Enum} from '@/library/enumerations/Enumeration';

@Enum
class EmployeeAmount extends Enumeration {
    static ZERO_TO_FIFTEEN = 0;
    static FIFTEEN_TO_FIFTY = 1;
    static FIFTY_TO_ONE_HUNDRED = 2;
    static ONE_HUNDRED_TO_FIVE_HUNDRED = 3;
    static FIVE_HUNDRED_PLUS = 4;

    /**
     * The key used to retrieve translations for the enumeration keys.
     */
    static translationKey = 'employeeAmount';
}

export default EmployeeAmount;
