import Router, {NavigationGuard} from 'vue-router';
import Vue from 'vue';
import routes from '@/router/routes/index';
import store from '@/store';

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior: () => ({x: 0, y: 0}),
});

// Try to retrieve the authenticated user.
router.beforeEach(async(to, from, next) => {
    if (store.state.auth.apiToken && !store.state.auth.me) {
        await store.dispatch('auth/fetchUserByToken', store.state.auth.apiToken);
    }

    next();
});

// Handle middleware.
router.beforeEach((to, from, next) => {
    const vueInstance = router.app;
    let middlewares: NavigationGuard[] = [];

    // Build the middleware array with middlewares of its parents and itself.
    for (const match of to.matched) {
        if (!match.meta.middleware) {
            continue;
        }

        middlewares.push(...match.meta.middleware);
    }

    // Remove duplicate middlewares.
    middlewares = middlewares.filter((item, index) => {
        return middlewares.indexOf(item) === index;
    });

    // Execute each middleware.
    for (const middleware of middlewares) {
        const result = middleware.bind(vueInstance)(to, from, next);

        // The middleware should return false when it initiated a redirect.
        if (result === false) {
            return result;
        }
    }

    return next();
});

export default router;
