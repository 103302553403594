










import {Component, Prop, Vue} from 'vue-property-decorator';
import {FormatValue} from '@/library/plugins/Format';

@Component
export default class FormatNumber extends Vue {
    @Prop({type: [Number, Object], default: null})
        value!: FormatValue;

    @Prop({type: [String, Object], default: 'span'})
        tag!: string | Vue;

    /**
     * If the formatted text's length is >= this threshold,
     * tooltip will be shown.
     */
    @Prop({type: Number, default: 10})
        tooltipThreshold!: number;

    get showTooltip():boolean {
        // Currently can't detect if the text has been truncated or not,
        // thus check the formatted text's length. Only show tooltip if
        // the text length >= the given threshold.
        return this.formattedStandard.length >= this.tooltipThreshold;
    }

    get formattedStandard(): string {
        return this.$format(this.value, {notation: 'standard'});
    }
}
