import {Route, RouteConfig} from 'vue-router';
import {applySidebarParent, prefixRoutes} from '@/router/helpers';

const routes: RouteConfig[] = prefixRoutes('invoices', [
    {
        path: '/',
        name: 'invoices.index',
        meta: {
            permissions: 'read:companies:invoices',
        },
        component: () => import('@/pages/invoices/Index.vue'),
    },
    {
        path: ':id',
        name: 'invoices.show',
        meta: {
            permissions: 'read:companies:invoices',
        },
        props: (route: Route) => ({
            invoiceId: Number.parseInt(route.params.id),
        }),
        component: () => import('@/pages/invoices/Show.vue'),
    },
]);

applySidebarParent('invoices.index', routes);

export default routes;
