















import Vue from 'vue';

export default Vue.extend({
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        success: {
            type: Boolean,
            default: false,
        },
        error: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        idleIcon: {
            type: String,
            default: '',
        },
    },

    computed: {
        icon(): string {
            if (this.loading) {
                return 'spinner';
            }

            if (this.success) {
                return 'check';
            }

            if (this.error) {
                return 'exclamation-triangle';
            }

            return this.idleIcon;
        },
    },
});
