import Role from '@/library/enumerations/Role';
import {Roles} from '@/models/Role';
import {uniq} from 'lodash';

export default class HasRoles {
    /**
     * The roles of the model.
     */
    roles!: Roles;

    get isCompanyAdmin(): boolean {
        return this.hasRoles(Role.COMPANY_ADMIN);
    }

    get isSteelSolutionsUser(): boolean {
        return this.hasRoles([Role.STEEL_SOLUTIONS_ADMIN, Role.STEEL_SOLUTIONS_EMPLOYEE], false);
    }

    /**
     * Checks if the model has certain role(s).
     * @param roles
     * @param withinAll Should contains all given roles
     */
    hasRoles(roles: number | number[], withinAll: boolean = true) {
        const requested = Array.isArray(roles) ? uniq(roles) : [roles];

        const granted = this.roles.where(role => requested.includes(Role.value(role.id) as number));

        return withinAll
            ? granted.length === requested.length
            : !!granted.length;
    }
}
