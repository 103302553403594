import '@formatjs/intl-numberformat/polyfill';
import '@formatjs/intl-numberformat/locale-data/en';
import {createIntl, createIntlCache} from '@formatjs/intl';
import VueI18Next from '@/translations/VueI18Next';

const cache = createIntlCache();

const intl = createIntl({
    locale: VueI18Next.i18next.language,
    defaultLocale: 'en-GB',
}, cache);

export default intl;
