import Vue, {PluginObject} from 'vue';
import Currency from '@/library/enumerations/Currency';
import {NumberFormatOptions} from '@formatjs/ecma402-abstract';
import {Price} from '@/library/types/Price';
import {get} from 'lodash';
import intl from '@/translations/Intl';

interface FormatPluginOptions extends NumberFormatOptions {
    locale?: string | string[];
    fallback?: string;
    numberFractionDigits?: number;
}

type FormatValue = number | Price | undefined | null;

type FormatFn = (value: FormatValue, options?: FormatPluginOptions) => string;

const isPrice = (value: number | null | Price): value is Price => {
    return value !== null
        && typeof value === 'object'
        && 'currency' in value
        && 'amount' in value;
};

const createFormatFn = (globalOptions?: FormatPluginOptions): FormatFn => {
    return (value: FormatValue, options: FormatPluginOptions = {}) => {
        const formatOptions = {
            ...globalOptions,
            ...options,
        };

        // If value is null or undefined, show fallback.
        if (
            value === undefined
            || value === null
            || (
                isPrice(value)
                && (value.amount === undefined || value.amount === null)
            )
        ) {
            return get(formatOptions, 'fallback', '-');
        }

        let amount = value;

        if (isPrice(value)) {
            amount = value.amount;

            // Force a price to display as 1.00 instead of 1 when below 1k.
            if (amount < 1000) {
                formatOptions.notation = 'standard';
            }

            formatOptions.style = 'currency';

            if (typeof value.currency === 'string') {
                // The Soft Solutions API could return a price with currency
                // 'Unknown', which the .formatNumber won't accept.
                formatOptions.currency = value.currency !== 'Unknown'
                    ? value.currency
                    : undefined;
            } else {
                formatOptions.currency = Currency.key(value.currency) || undefined;
            }

            if (!formatOptions.currency) {
                return get(formatOptions, 'fallback', '-');
            }
        } else {
            const fractionDigits = Math.pow(10, get(formatOptions, 'numberFractionDigits', 0));

            amount = Math.ceil(value * fractionDigits) / fractionDigits;
        }

        return intl.formatNumber(amount as number, formatOptions);
    };
};

const FormatPlugin: PluginObject<FormatPluginOptions> = {
    install(vue: typeof Vue, options?: FormatPluginOptions) {
        vue.prototype.$format = createFormatFn(options);
    },
};

export default FormatPlugin;

export {FormatFn, FormatPluginOptions, FormatValue};
