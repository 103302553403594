import {NavigationGuardNext, Route} from 'vue-router';

function guest<V extends Vue>(this: V, to: Route, from: Route, next: NavigationGuardNext<V>) {
    if (this.$store.state.auth.me) {
        next({name: 'home'});

        return false;
    }

    return true;
}

export default guest;
