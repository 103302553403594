import {Module, Mutation, VuexModule} from 'vuex-module-decorators';
import {OrderSummary} from '@/models/Order';
import {ProductSummary} from '@/models/Product';

@Module({
    namespaced: true,
})
export default class Counts extends VuexModule {
    products: ProductSummary | null = null;

    orders: OrderSummary | null = null;

    @Mutation
    clear() {
        this.products = null;
        this.orders = null;
    }
}
