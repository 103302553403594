import Base, {RequestOptions} from 'vue-mc/src/Structures/Base';
import {cloneDeep, each, isEmpty, isEqual, merge, omit, pick} from 'lodash';
import $t from 'i18next';
import {AxiosRequestConfig} from 'axios';
import BaseModel from 'vue-mc/src/Structures/Model';
import Collection from 'vue-mc/src/Structures/Collection';
import {Constructor} from 'vue/types/options';
import {Location} from 'vue-router';
import RegistersAttributes from './mixins/RegistersAttributes';
import Request from './Request';
import Response from './Response';
import ResponseError from 'vue-mc/src/Errors/ResponseError';
import Vue from 'vue';
import {applyMixins} from '@/library/helpers';
import moment from 'moment';

interface Model extends RegistersAttributes {}

class Model extends BaseModel {
    protected baseUrl!: string;

    /**
     * If model changed at least once it's touched!
     */
    touched: boolean = false;

    /**
     * @override to update touched on change event triggered
     */
    constructor(attributes = {}, collection: Collection | null = null, options = {}) {
        super(attributes, collection, options);

        this.on('change', () => {
            this.touched = true;
        });

        this.on('sync, save.success, reset', () => {
            this.touched = false;
        });
    }

    /**
     * @returns {string|undefined} Endpoint of this model.
     */
    static get endpoint(): string | undefined {
        return (new this).getOption('endpoint') || undefined;
    }

    /**
     * @returns {boolean} `true` if this model has errors, `false` otherwise.
     */
    get hasErrors(): boolean {
        return !isEmpty(this.errors);
    }

    /**
     * @returns {string} First error message that this model has.
     */
    get firstErrorMessage(): string {
        if (this.hasErrors) {
            const firstError = Object.values(this.errors)[0];

            return typeof firstError === 'string'
                ? firstError
                : firstError[0];
        }

        return '';
    }

    get errorMessage(): string {
        return this.getErrors().message as string || '';
    }

    /**
     * Mapping of model attributes to the class it should be transformed into.
     */
    transformations(): Record<string, Constructor | typeof moment> {
        return {};
    }

    /**
     * Assigns all given model data to the model's attributes and reference.
     * This method is called on instantiation, fetch, and save success.
     */
    assign(attrs: Record<string, any>): void {
        // Transform each given attribute into the desired class instance.
        Object.entries(this.transformations()).forEach(([key, constructor]) => {
            const attribute = attrs[key] || this.defaults()[key];

            if (attribute) {
                attrs[key] = attribute instanceof constructor
                    ? attribute
                    // @ts-ignore TS thinks we can't do `new moment()`, but we can.
                    : new constructor(attribute);
            }
        });

        super.assign(attrs);
    }

    /**
     * Overrides vue-mc's Model to use our own Request class.
     *
     * @param {AxiosRequestConfig} config
     * @returns {Request} A new `Request` using the given configuration.
     */
    createRequest(config: AxiosRequestConfig): Request {
        return new Request(config, this.baseUrl);
    }

    /**
     * @returns {Object} Default HTTP methods.
     */
    getDefaultMethods(): object {
        return merge(super.getDefaultMethods(), {
            update: 'PATCH',
        });
    }

    /**
     * Returns the default options for this model.
     *
     * @returns {Object}
     */
    getDefaultOptions(): object {
        return merge(super.getDefaultOptions(), {
            patch: true,
            saveUnchanged: false,
            useFirstErrorOnly: true,

            mutateOnChange: false,
            mutateBeforeSync: true,
            mutateBeforeSave: false,

            validateOnChange: false,
            validateOnSave: true,
            validateRecursively: false,

            // If set false, the model won't be removed from collection after `delete()`.
            removeAfterDeleted: true,
        });
    }

    /**
     * Tries to construct our API route based on endpoint and identifier.
     */
    getRoute(key: string, fallback?: string): string {
        let route;

        try {
            route = super.getRoute(key, fallback);
        } catch (error) {
            route = this.getOption('endpoint');

            if (!route) {
                throw new Error('Model endpoint is not yet defined.');
            }

            if (key !== 'create') {
                route += `/{${this.getOption('identifier')}}`;
            }
        }

        const prefix = this.getOption('routePrefix');

        if (prefix) {
            route = `${prefix}/${route}`;
        }

        return route;
    }

    /**
     * @returns {Object} The data to send to the server when saving this model.
     */
    getSaveData(): Record<string, any> {
        let data: Record<string, any> = {};

        if (this.isExisting() && this.shouldPatch()) {
            // If patching, only send changed attributes ...
            data = pick(this.attributes, this.changed() || []);
        } else {
            // All attributes should be sent ...
            data = cloneDeep(this.attributes);

            if (!this.isExisting()) {
                delete data[this.getOption('identifier', 'id')];
            }
        }

        return data;
    }

    /**
     * Overrides vue-mc to clear errors before saving.
     */
    onSave() {
        this.clearErrors();

        for (const attribute in this.attributes) {
            if (
                this.attributes[attribute] instanceof Collection
                || this.attributes[attribute] instanceof Model
            ) {
                this.attributes[attribute].clearErrors();
            }
        }

        return super.onSave();
    }

    /**
     * Called when a fetch request failed.
     */
    onFetchFailure(error: ResponseError) {
        super.onFetchFailure(error);

        this.recordErrorMessage(error);
    }

    /**
     * Set errors to sub models.
     */
    setAttributeErrors(attribute: string, errors?: any): void {
        super.setAttributeErrors(attribute, errors);

        const subAttributes = attribute.split('.');

        if (subAttributes.length === 3) {
            const [model, key, subAttribute] = subAttributes;

            this.attributes[model]?.models[key]?.setAttributeErrors(subAttribute, errors);
        }
    }

    /**
     * Called when a save request resulted in an unexpected error,
     * eg. an internal server error (500)
     */
    onFatalSaveFailure(error: ResponseError, response: Response | undefined): void {
        super.onFatalSaveFailure(error, response);

        this.recordErrorMessage(error);
    }

    /**
     * Called when a delete request was successful. Override vue-mc so that
     * if `removeAfterDeleted` option is false, the model won't be cleared nor
     * removed from its collections.
     */
    onDeleteSuccess(): void {
        if (this.getOption('removeAfterDeleted')) {
            this.clear();
            this.removeFromAllCollections();
        }

        Vue.set(this, 'deleting', false);
        Vue.set(this, 'fatal', false);

        this.emit('delete', {error: null});
    }

    /**
     * ========================================================================
     * Additional Methods
     * ========================================================================
     */

    /**
     * Returns the translated string for the given attribute. Typically used to
     * retrieve the attribute's label or placeholder.
     */
    getTranslation(attribute: string, suffix: string = 'label'): string {
        // The convention for the attribute's translation key:
        // {model 'translation' or API endpoint}.model.{attribute}.{'label'|'placeholder'}
        // E.g.: 'leagues.model.name.label'
        return $t.t(`${this.getOption('translation') || this.getOption('endpoint')}.model.${attribute}.${suffix}`);
    }

    /**
     * Returns a specific vue-router Location of this instance.
     * By default, the 'edit' Location will be returned.
     *
     * E.g.: `league.getLocation()` => {
     *     name: 'leagues.edit',
     *     params: {slug: 'league-slug'},
     * }
     */
    getLocation(name: string = 'edit', query: Record<string, any> = {}): Location {
        // The location name should start with this model's API endpoint,
        // followed by the specificly requested route name.
        const location : Location = {
            name: `${this.getLocationName()}.${name}`,
        };

        // If the requested location is not `index` and not `create` ...
        if (name != 'index' && name != 'create') {
            // add this model's identifier as location params.
            location.params = {
                [this.getOption('identifier')]: this.identifier(),
            };
        }

        if (Object.keys(query).length) {
            location.query = query;
        }

        return location;
    }

    /**
     * Returns a route name.
     */
    getLocationName() {
        return this.getOption('endpoint');
    }

    /**
     * Returns an object that contains the errors of an attribute.
     *
     * Example:
     * Current model state =>
     * {
     *   _errors: {
     *     'email': 'Email is required',
     *     'address.street': 'Street is required',
     *     'address.number': 'Number is required',
     *   }
     * }
     *
     * this.getAttributeErrorObject('address') =>
     * {
     *   street: 'Street is required',
     *   number: 'Number is required',
     * }
     */
    getAttributeErrorObject(attribute: string): Record<string, string | string[]> {
        const errors = super.getErrors();

        // Instantiate the error object that will be returned.
        const attributeError: Record<string, string | string[]> = {};

        if (errors && attribute) {
            // Iterate through all errors that the model has.
            Object.entries(errors).forEach(([key, value]) => {
                // Capture only errors that belongs to the `attribute`.
                if (key.startsWith(attribute)) {
                    // Remove the `{attribute}.` prefix in the error key.
                    const errorKey = key.replace(`${attribute}.`, '');

                    if (errorKey) {
                        attributeError[errorKey] = value;
                    }
                }
            });
        }

        return attributeError;
    }

    /**
     * Records the general error message in the `_errors` attribute.
     */
    recordErrorMessage(error: ResponseError) {
        const message = (error.getResponse() as Response).getErrorMessage();

        if (message) {
            this.setErrors({message});
        }
    }

    /**
     * Set the identifier value of this instance.
     */
    setIdentifier(identifier: number | string): this {
        this.set(this.getOption('identifier'), identifier);

        return this;
    }

    /**
     * Override this just to fix it's return type.
     */
    clone(): this {
        return super.clone() as this;
    }

    /**
     * Change method cannot check deep relations, so we override
     * it to avoid it when value is type of model or collection.
     */
    changed(): string[] | false {
        const changed: string[] = [];

        each(this.attributes, (value, attribute): void => {
            if (!(value instanceof Base) && !isEqual(value, this.saved(attribute))) {
                changed.push(attribute);
            }
        });

        return !isEmpty(changed) ? changed : false;
    }

    filterKeys(keys: string[]) {
        return Object.fromEntries(Object.entries(this.attributes).filter(([key]) => keys.includes(key)));
    }

    /**
     * Make a DELETE request to API. However, once deleted, the model won't be
     * cleared nor removed from its collection. Instead the `deletedAt` attribute
     * will be set.
     */
    async softDelete(options: RequestOptions = {}) {
        this.setOption('removeAfterDeleted', false);

        await this.delete(options);

        this.set('deletedAt', new Date().toISOString());
    }

    /**
     * Determines if the model has default properties.
     */
    isDefault(except: string[] = [], only: string[] | null = null): boolean {
        if (only !== null) {
            return isEqual(
                pick(this.attributes, only),
                pick(this.defaults(), only),
            );
        }

        return isEqual(
            omit(this.attributes, except),
            omit(this.defaults(), except),
        );
    }
}

applyMixins(Model, [RegistersAttributes]);

export default Model;
