import {Action, Module, Mutation, VuexModule} from 'vuex-module-decorators';
import Collection from '@/models/vue-mc/Collection';

@Module({
    namespaced: true,
})
export default class Cache extends VuexModule {
    /**
     * Collection fetch results will be stored in this object:
     * key: Constructor of the collection.
     * value: The value of the fetch result.
     */
    collectionCache = new Map<Function, any>();

    @Mutation
    clear() {
        this.collectionCache = new Map<Function, any>();
    }

    @Mutation
    store({key, value}: {key: Function, value: any | any[]}) {
        // Create a copy of the map so we do not change the previous state.
        const copy = new Map(this.collectionCache);

        copy.set(key, value);

        this.collectionCache = copy;
    }

    /**
     * Fetches the collection using {@link Collection.fetch} unless the fetch result is already in {@link collectionCache}.
     * @param collection
     *
     * @return {Model[]} - The list of fetched models.
     */
    @Action
    async fetch<T extends Collection>(collection: T) {
        const type = collection.constructor;

        if (this.collectionCache.has(type)) {
            collection.clearModels();

            collection.add(this.collectionCache.get(type));
        } else {
            await collection.fetch();

            this.context.commit('store', {key: type, value: collection.models});
        }

        return collection.models;
    }
};
