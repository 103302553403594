import Enumeration, {Enum} from '@/library/enumerations/Enumeration';

/**
 * List of currencies extracted from https://en.wikipedia.org/wiki/ISO_4217
 */

@Enum
export default class Currency extends Enumeration {
    /**
     * The key used to retrieve translations for the enumeration keys.
     */
    static translationKey = 'currency';

    // United Arab Emirates dirham
    static AED = 784;

    // Afghan afghani
    static AFN = 971;

    // Albanian lek
    static ALL = 8;

    // Armenian dram
    static AMD = 51;

    // Netherlands Antillean guilder
    static ANG = 532;

    // Angolan kwanza
    static AOA = 973;

    // Argentine peso
    static ARS = 32;

    // Australian dollar
    static AUD = 36;

    // Aruban florin
    static AWG = 533;

    // Azerbaijani manat
    static AZN = 944;

    // Bosnia and Herzegovina convertible mark
    static BAM = 977;

    // Barbados dollar
    static BBD = 52;

    // Bangladeshi taka
    static BDT = 50;

    // Bulgarian lev
    static BGN = 975;

    // Bahraini dinar
    static BHD = 48;

    // Burundian franc
    static BIF = 108;

    // Bermudian dollar
    static BMD = 60;

    // Brunei dollar
    static BND = 96;

    // Boliviano
    static BOB = 68;

    // Bolivian Mvdol (funds code)
    static BOV = 984;

    // Brazilian real
    static BRL = 986;

    // Bahamian dollar
    static BSD = 44;

    // Bhutanese ngultrum
    static BTN = 64;

    // Botswana pula
    static BWP = 72;

    // Belarusian ruble
    static BYN = 933;

    // Belize dollar
    static BZD = 84;

    // Canadian dollar
    static CAD = 124;

    // Congolese franc
    static CDF = 976;

    // WIR Euro (complementary currency)
    static CHE = 947;

    // Swiss franc
    static CHF = 756;

    // WIR Franc (complementary currency)
    static CHW = 948;

    // Unidad de Fomento (funds code)
    static CLF = 990;

    // Chilean peso
    static CLP = 152;

    // Chinese yuan[8]
    static CNY = 156;

    // Colombian peso
    static COP = 170;

    // Unidad de Valor Real (UVR) (funds code)[9]
    static COU = 970;

    // Costa Rican colon
    static CRC = 188;

    // Cuban convertible peso
    static CUC = 931;

    // Cuban peso
    static CUP = 192;

    // Cape Verdean escudo
    static CVE = 132;

    // Czech koruna
    static CZK = 203;

    // Djiboutian franc
    static DJF = 262;

    // Danish krone
    static DKK = 208;

    // Dominican peso
    static DOP = 214;

    // Algerian dinar
    static DZD = 12;

    // Egyptian pound
    static EGP = 818;

    // Eritrean nakfa
    static ERN = 232;

    // Ethiopian birr
    static ETB = 230;

    // Euro
    static EUR = 978;

    // Fiji dollar
    static FJD = 242;

    // Falkland Islands pound
    static FKP = 238;

    // Pound sterling
    static GBP = 826;

    // Georgian lari
    static GEL = 981;

    // Ghanaian cedi
    static GHS = 936;

    // Gibraltar pound
    static GIP = 292;

    // Gambian dalasi
    static GMD = 270;

    // Guinean franc
    static GNF = 324;

    // Guatemalan quetzal
    static GTQ = 320;

    // Guyanese dollar
    static GYD = 328;

    // Hong Kong dollar
    static HKD = 344;

    // Honduran lempira
    static HNL = 340;

    // Croatian kuna
    static HRK = 191;

    // Haitian gourde
    static HTG = 332;

    // Hungarian forint
    static HUF = 348;

    // Indonesian rupiah
    static IDR = 360;

    // Israeli new shekel
    static ILS = 376;

    // Indian rupee
    static INR = 356;

    // Iraqi dinar
    static IQD = 368;

    // Iranian rial
    static IRR = 364;

    // Icelandic króna
    static ISK = 352;

    // Jamaican dollar
    static JMD = 388;

    // Jordanian dinar
    static JOD = 400;

    // Japanese yen
    static JPY = 392;

    // Kenyan shilling
    static KES = 404;

    // Kyrgyzstani som
    static KGS = 417;

    // Cambodian riel
    static KHR = 116;

    // Comoro franc
    static KMF = 174;

    // North Korean won
    static KPW = 408;

    // South Korean won
    static KRW = 410;

    // Kuwaiti dinar
    static KWD = 414;

    // Cayman Islands dollar
    static KYD = 136;

    // Kazakhstani tenge
    static KZT = 398;

    // Lao kip
    static LAK = 418;

    // Lebanese pound
    static LBP = 422;

    // Sri Lankan rupee
    static LKR = 144;

    // Liberian dollar
    static LRD = 430;

    // Lesotho loti
    static LSL = 426;

    // Libyan dinar
    static LYD = 434;

    // Moroccan dirham
    static MAD = 504;

    // Moldovan leu
    static MDL = 498;

    // Malagasy ariary
    static MGA = 969;

    // Macedonian denar
    static MKD = 807;

    // Myanmar kyat
    static MMK = 104;

    // Mongolian tögrög
    static MNT = 496;

    // Macanese pataca
    static MOP = 446;

    // Mauritanian ouguiya
    static MRU = 929;

    // Mauritian rupee
    static MUR = 480;

    // Maldivian rufiyaa
    static MVR = 462;

    // Malawian kwacha
    static MWK = 454;

    // Mexican peso
    static MXN = 484;

    // Mexican Unidad de Inversion (UDI) (funds code)
    static MXV = 979;

    // Malaysian ringgit
    static MYR = 458;

    // Mozambican metical
    static MZN = 943;

    // Namibian dollar
    static NAD = 516;

    // Nigerian naira
    static NGN = 566;

    // Nicaraguan córdoba
    static NIO = 558;

    // Norwegian krone
    static NOK = 578;

    // Nepalese rupee
    static NPR = 524;

    // New Zealand dollar
    static NZD = 554;

    // Omani rial
    static OMR = 512;

    // Panamanian balboa
    static PAB = 590;

    // Peruvian sol
    static PEN = 604;

    // Papua New Guinean kina
    static PGK = 598;

    // Philippine peso[12]
    static PHP = 608;

    // Pakistani rupee
    static PKR = 586;

    // Polish złoty
    static PLN = 985;

    // Paraguayan guaraní
    static PYG = 600;

    // Qatari riyal
    static QAR = 634;

    // Romanian leu
    static RON = 946;

    // Serbian dinar
    static RSD = 941;

    // Russian ruble
    static RUB = 643;

    // Rwandan franc
    static RWF = 646;

    // Saudi riyal
    static SAR = 682;

    // Solomon Islands dollar
    static SBD = 90;

    // Seychelles rupee
    static SCR = 690;

    // Sudanese pound
    static SDG = 938;

    // Swedish krona/kronor
    static SEK = 752;

    // Singapore dollar
    static SGD = 702;

    // Saint Helena pound
    static SHP = 654;

    // Sierra Leonean leone
    static SLL = 694;

    // Somali shilling
    static SOS = 706;

    // Surinamese dollar
    static SRD = 968;

    // South Sudanese pound
    static SSP = 728;

    // São Tomé and Príncipe dobra
    static STN = 930;

    // Salvadoran colón
    static SVC = 222;

    // Syrian pound
    static SYP = 760;

    // Swazi lilangeni
    static SZL = 748;

    // Thai baht
    static THB = 764;

    // Tajikistani somoni
    static TJS = 972;

    // Turkmenistan manat
    static TMT = 934;

    // Tunisian dinar
    static TND = 788;

    // Tongan paʻanga
    static TOP = 776;

    // Turkish lira
    static TRY = 949;

    // Trinidad and Tobago dollar
    static TTD = 780;

    // New Taiwan dollar
    static TWD = 901;

    // Tanzanian shilling
    static TZS = 834;

    // Ukrainian hryvnia
    static UAH = 980;

    // Ugandan shilling
    static UGX = 800;

    // United States dollar
    static USD = 840;

    // United States dollar (next day) (funds code)
    static USN = 997;

    // Uruguay Peso en Unidades Indexadas (URUIURUI) (funds code)
    static UYI = 940;

    // Uruguayan peso
    static UYU = 858;

    // Unidad previsional[14]
    static UYW = 927;

    // Uzbekistan som
    static UZS = 860;

    // Venezuelan bolívar soberano[12]
    static VES = 928;

    // Vietnamese đồng
    static VND = 704;

    // Vanuatu vatu
    static VUV = 548;

    // Samoan tala
    static WST = 882;

    // CFA franc BEAC
    static XAF = 950;

    // Silver (one troy ounce)
    static XAG = 961;

    // Gold (one troy ounce)
    static XAU = 959;

    // European Composite Unit (EURCO) (bond market unit)
    static XBA = 955;

    // European Monetary Unit (E.M.U.-6) (bond market unit)
    static XBB = 956;

    // European Unit of Account 9 (E.U.A.-9) (bond market unit)
    static XBC = 957;

    // European Unit of Account 17 (E.U.A.-17) (bond market unit)
    static XBD = 958;

    // East Caribbean dollar
    static XCD = 951;

    // Special drawing rights
    static XDR = 960;

    // CFA franc BCEAO
    static XOF = 952;

    // Palladium (one troy ounce)
    static XPD = 964;

    // CFP franc (franc Pacifique)
    static XPF = 953;

    // Platinum (one troy ounce)
    static XPT = 962;

    // SUCRE
    static XSU = 994;

    // Code reserved for testing
    static XTS = 963;

    // ADB Unit of Account
    static XUA = 965;

    // No currency
    static XXX = 999;

    // Yemeni rial
    static YER = 886;

    // South African rand
    static ZAR = 710;

    // Zambian kwacha
    static ZMW = 967;

    // Zimbabwean dollar
    static ZWL = 932;
}
