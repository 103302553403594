







import {Component, Prop, Vue} from 'vue-property-decorator';

/**
 * A component to display image thumbnail. Typically used for user's profile picture.
 */
@Component
export default class OwThumbnail extends Vue {
    /**
     * The image object returned by API, containing several image URLs.
     *
     * Ex:
     * {
     *   original: originalUrl,
     *   thumb: thumbnailUrl,
     * }
     */
    @Prop({
        type: [Object, String],
        default: null,
    })
    public value!: Record<string, string> | null;

    /**
     * The key of the chosen image URL.
     */
    @Prop({type: String, default: 'thumb'})
    public valueKey!: string;

    get style() {
        const bgImage = this.src;

        return {
            backgroundImage: bgImage ? `url(${bgImage})` : 'none',
        };
    }

    /**
     * The src value that is used to display the image.
     */
    get src(): string | undefined {
        if (this.value) {
            if (typeof this.value === 'object') {
                return this._.get(this.value, this.valueKey);
            }

            return this.value;
        }

        return undefined;
    }
};
