import {Route, RouteConfig} from 'vue-router';
import {applySidebarParent, prefixRoutes} from '@/router/helpers';

const routes: RouteConfig[] = prefixRoutes('orders', [
    {
        path: '/',
        name: 'orders.index',
        meta: {
            permissions: 'read:companies:orders',
        },
        component: () => import('@/pages/orders/Index.vue'),
    },
    {
        path: ':id',
        name: 'orders.show',
        meta: {
            permissions: 'read:companies:orders',
        },
        props: (route: Route) => ({
            orderId: Number.parseInt(route.params.id),
        }),
        component: () => import('@/pages/orders/Show.vue'),
    },
    {
        path: 'create/inbound',
        name: 'orders.create.inbound',
        meta: {
            permissions: 'create:orders',
        },
        redirect: () => {
            return {
                name: 'orders.create.inbound.agreement',
            };
        },
        component: () => import('@/pages/orders/create/inbound/Layout.vue'),
        children: [
            {
                path: 'agreement',
                name: 'orders.create.inbound.agreement',
                meta: {
                    permissions: 'read:companies:agreements',
                },
                component: () => import('@/pages/orders/create/common/Agreement.vue'),
            },
            {
                path: 'origin-destination',
                name: 'orders.create.inbound.origin-destination',
                meta: {
                    permissions: ['read:agreements:addresses', 'read:agreements:routes'],
                },
                component: () => import('@/pages/orders/create/common/OriginDestination.vue'),
            },
            {
                path: 'products',
                name: 'orders.create.inbound.products',
                component: () => import('@/pages/orders/create/inbound/Products.vue'),
            },
            {
                path: 'transport',
                name: 'orders.create.inbound.transport',
                meta: {
                    permissions: 'read:agreements:routes',
                },
                component: () => import('@/pages/orders/create/common/Transport.vue'),
            },
            {
                path: 'overview',
                name: 'orders.create.inbound.overview',
                component: () => import('@/pages/orders/create/common/Overview.vue'),
            },
        ],
    },
    {
        path: 'create/outbound',
        name: 'orders.create.outbound',
        meta: {
            permissions: 'create:orders',
        },
        redirect: () => {
            return {
                name: 'orders.create.outbound.agreement',
            };
        },
        component: () => import('@/pages/orders/create/outbound/Layout.vue'),
        children: [
            {
                path: 'agreement',
                name: 'orders.create.outbound.agreement',
                meta: {
                    permissions: 'read:companies:agreements',
                },
                component: () => import('@/pages/orders/create/common/Agreement.vue'),
            },
            {
                path: 'origin-destination',
                name: 'orders.create.outbound.origin-destination',
                meta: {
                    permissions: ['read:agreements:addresses', 'read:agreements:routes'],
                },
                component: () => import('@/pages/orders/create/common/OriginDestination.vue'),
            },
            {
                path: 'products',
                name: 'orders.create.outbound.products',
                component: () => import('@/pages/orders/create/outbound/Products.vue'),
            },
            {
                path: 'transport',
                name: 'orders.create.outbound.transport',
                meta: {
                    permissions: 'read:agreements:routes',
                },
                component: () => import('@/pages/orders/create/common/Transport.vue'),
            },
            {
                path: 'overview',
                name: 'orders.create.outbound.overview',
                component: () => import('@/pages/orders/create/common/Overview.vue'),
            },
        ],
    },
    {
        path: 'create/transport',
        name: 'orders.create.transport',
        meta: {
            permissions: 'create:orders',
        },
        redirect: () => {
            return {
                name: 'orders.create.transport.agreement',
            };
        },
        component: () => import('@/pages/orders/create/transport/Layout.vue'),
        children: [
            {
                path: 'agreement',
                name: 'orders.create.transport.agreement',
                meta: {
                    permissions: 'read:companies:agreements',
                },
                component: () => import('@/pages/orders/create/common/Agreement.vue'),
            },
            {
                path: 'origin-destination',
                name: 'orders.create.transport.origin-destination',
                meta: {
                    permissions: 'read:agreements:addresses',
                },
                component: () => import('@/pages/orders/create/common/OriginDestination.vue'),
            },
            {
                path: 'products',
                name: 'orders.create.transport.products',
                component: () => import('@/pages/orders/create/transport/Products.vue'),
            },
            {
                path: 'transport',
                name: 'orders.create.transport.transport',
                meta: {
                    permissions: 'read:agreements:routes',
                },
                component: () => import('@/pages/orders/create/common/Transport.vue'),
            },
            {
                path: 'overview',
                name: 'orders.create.transport.overview',
                component: () => import('@/pages/orders/create/common/Overview.vue'),
            },
        ],
    },
    {
        path: 'create/direct-delivery',
        name: 'orders.create.direct-delivery',
        meta: {
            permissions: 'create:orders',
        },
        redirect: () => {
            return {
                name: 'orders.create.direct-delivery.agreement',
            };
        },
        component: () => import('@/pages/orders/create/direct-delivery/Layout.vue'),
        children: [
            {
                path: 'agreement',
                name: 'orders.create.direct-delivery.agreement',
                meta: {
                    permissions: 'read:companies:agreements',
                },
                component: () => import('@/pages/orders/create/common/Agreement.vue'),
            },
            {
                path: 'origin-destination',
                name: 'orders.create.direct-delivery.origin-destination',
                meta: {
                    permissions: ['read:agreements:addresses', 'read:agreements:routes'],
                },
                component: () => import('@/pages/orders/create/common/OriginDestination.vue'),
            },
            {
                path: 'products',
                name: 'orders.create.direct-delivery.products',
                component: () => import('@/pages/orders/create/direct-delivery/Products.vue'),
            },
            {
                path: 'transport',
                name: 'orders.create.direct-delivery.transport',
                meta: {
                    permissions: 'read:agreements:routes',
                },
                component: () => import('@/pages/orders/create/common/Transport.vue'),
            },
            {
                path: 'overview',
                name: 'orders.create.direct-delivery.overview',
                component: () => import('@/pages/orders/create/common/Overview.vue'),
            },
        ],
    },
]);

applySidebarParent('orders.index', routes);

export default routes;
