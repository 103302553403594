import {elementThresholdReached, firstScrollingParent} from '@/library/helpers';
import {DirectiveBinding} from 'vue/types/options';
import {debounce} from 'lodash';
/**
 * A Vue directive that will enable infinite scrolling behavior.
 *
 * Example usages:
 * - <element v-infinite-scroll:100="loadMore" />
 *   -> When the element itself is scrolled DOWN until 100px before its bottom, "loadMore" is called.
 *   -> 100 is an optional `offset`, defaulted to 50 {@link DEFAULT_OFFSET}.
 *
 * - <element v-infinite-scroll:100.up="loadMore" />
 *   -> When the element itself is scrolled UP until 100px before its top, "loadMore" is called.
 *
 * - <element v-infinite-scroll:100.document="loadMore" />
 *   -> When the DOCUMENT is scrolled DOWN until 100px before document's bottom, "loadMore" is called.
 *
 * - <element v-infinite-scroll.document.up="loadMore" />
 *   -> When the DOCUMENT has been scrolled UP until 50px before document's top, "loadMore" is called.
 *
 * - <element v-infinite-scroll.parent="loadMore" />
 *   -> When the first scrollable parent element has been scrolled DOWN until 50px before its bottom, "loadMore" is called.
 */
const directive = (() => {
    const DEFAULT_OFFSET = 50;

    /**
     * Parses the binding's offset argument, returns {@link DEFAULT_OFFSET} when arg is undefined or not a number.
     *
     * @param binding
     * @return {number}
     */
    function getOffset(binding: DirectiveBinding) {
        if (binding.arg === undefined) {
            return DEFAULT_OFFSET;
        }

        const parsed = parseInt(binding.arg);

        return parsed !== Number.NaN ? parsed : DEFAULT_OFFSET;
    }

    /**
     * Determines the target element on which the scroll event listener will be attached to.
     * It is either the document or the `el` itself, depending on `binding.modifiers.document`.
     *
     * @param {Element} el
     * @param binding
     * @return {Element}
     */
    function getTargetElement(el: Element, binding: DirectiveBinding): Element {
        if (binding.modifiers.parent === true) {
            return firstScrollingParent(el);
        }

        if (binding.modifiers.document === true) {
            return document.scrollingElement as Element;
        }

        return el;
    }

    /**
     * Creates a new scroll handler for element. Calls callback function when the element
     * has been scrolled to its (almost) bottom OR top, depending on `binding.modifiers.up`.
     *
     * @param {Element} el
     * @param {Element} target
     * @param binding
     * @param {function} callback
     * @return {function(...[*]=)}
     */
    function createScrollHandler(el: Element, target: Element, binding: DirectiveBinding, callback: () => Promise<any>) {
        const offset = getOffset(binding);

        const scrollUp = binding.modifiers.up === true;

        return async function() {
            if (elementThresholdReached(target, offset, scrollUp)) {
                try {
                    await callback();
                } catch (error) {
                    removeScrollEventListener(el);
                }
            }
        };
    }

    /**
     * Removes the scroll event listener registered in the state for the element.
     * This is typically called when the directive is unbound or there is an error
     * when the callback is called.
     *
     * @param {Element} el
     */
    function removeScrollEventListener(el: Element) {
        if (!state.has(el)) {
            return;
        }

        // Retrieve the target element and scrollHandler from the state.
        const {target, scrollHandler} = state.get(el);

        if (target && typeof scrollHandler === 'function') {
            target.removeEventListener('scroll', scrollHandler);

            state.delete(el);
        }
    }

    /**
     * A WeakMap that is used to store the scrollHandler function, so it can be
     * removed when the directive is unbound.
     */
    const state = new WeakMap();

    return {
        /**
         * Setup scroll event handler when directive is inserted.
         *
         * @param el
         * @param binding
         */
        inserted: (el: Element, binding: DirectiveBinding) => {
            const callback = binding.value;

            if (typeof callback !== 'function') {
                throw new Error('v-infinite-scroll requires a function.');
            }

            // If the element already has infinite loop scroll listener attached,
            // remove the scroll event listener first.
            if (state.has(el)) {
                removeScrollEventListener(el);
            }

            let target: Element | Window = getTargetElement(el, binding);

            const scrollHandler = debounce(
                createScrollHandler(el, target, binding, callback),
                500,
            );

            // For an unknown reason, `<html>` is not firing scroll events,
            // thus we need to attach the listener to window.
            target = target === document.scrollingElement ? window : target;

            target.addEventListener('scroll', scrollHandler);

            // Store the target element and scrollHandler in state, so we can
            // remove event listener when the directive is unbound.
            state.set(el, {target, scrollHandler});
        },

        /**
         * Remove scroll event handler when directive is unbound.
         *
         * @param el
         */
        unbind: (el: Element) => {
            removeScrollEventListener(el);
        },
    };
})();

export default directive;
