import {Action, Module, Mutation, VuexModule} from 'vuex-module-decorators';
import ApiToken from '@/library/utils/ApiToken';
import User from '@/models/User';
import router from '@/router';

const apiToken = localStorage.getItem('apiToken');

@Module({
    namespaced: true,
})
export default class Auth extends VuexModule {
    /**
     * The current authenticated user.
     */
    me: User | null = null;

    /**
     * The api token of the current authenticated user.
     */
    apiToken: string | null = apiToken;

    /**
     * Sets the current authenticated user.
     */
    @Mutation
    setMe(user: User | Record<string, any> | null) {
        if (user) {
            this.me = user instanceof User ? user : new User(user);
        } else {
            this.me = null;
        }
    }

    /**
     * Sets the api token.
     */
    @Mutation
    setApiToken({apiToken, store = true}: { apiToken: string, store: boolean }) {
        this.apiToken = apiToken;

        if (apiToken && store) {
            localStorage.setItem('apiToken', apiToken);
        } else {
            localStorage.removeItem('apiToken');
        }
    }

    /**
     * Sets the current authenticated user and api token.
     */
    @Action
    login({user, apiToken}: { user: any, apiToken: string }) {
        this.context.commit('setMe', new User(user));

        this.context.commit('setApiToken', {apiToken});
    }

    /**
     * Sets the current authenticated user and api token to values that
     * indicate no user is authenticated.
     */
    @Action
    logout(redirect: boolean = true) {
        this.context.commit('setMe', null);

        this.context.commit('setApiToken', {});

        this.context.commit('cache/clear', null, {root: true});
        this.context.commit('counts/clear', null, {root: true});

        localStorage.clear();

        if (redirect) {
            router.push({name: 'auth.login'});
        }
    }

    @Action
    async fetchUserByToken(token?: string) {
        try {
            const apiToken = new ApiToken(token);

            const user = new User({id: apiToken.userId});

            await user.fetch({
                headers: {'Authorization': `Bearer ${apiToken.encoded}`},
            });

            this.context.commit('setMe', user);
        } catch (e) {
            console.error(e);

            if (!token) {
                this.context.commit('setApiToken', {});

                localStorage.removeItem('apiToken');
            }
        }
    }
}
