import {cloneDeep, defaultTo, first, get, isEqual, pick} from 'lodash';
import {PluginObject} from 'vue';
import VueLodash from 'vue-lodash';

const vueLodashPlugin: PluginObject<object> = {
    install(Vue) {
        // Enable `$vm` to use imported lodash functions.
        Vue.use(VueLodash, {lodash: {cloneDeep, defaultTo, first, get, isEqual, pick}});
    },
};

export default vueLodashPlugin;
