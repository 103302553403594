import {BToast, BvToastOptions} from 'bootstrap-vue';
import {invoke, merge} from 'lodash';
import {AxiosRequestConfig} from 'axios';
import Collection from '@/models/vue-mc/Collection';
import Request from '@/models/vue-mc/Request';
import Vue from 'vue';

/**
 * Runs through the properties of each mixin
 * and copy them over to the target.
 * Source: https://www.typescriptlang.org/docs/handbook/mixins.html
 */
function applyMixins(target: any, mixins: any[]) {
    mixins.forEach(mixin => {
        Object.getOwnPropertyNames(mixin.prototype).forEach(name => {
            if (name !== 'constructor') {
                Object.defineProperty(
                    target.prototype,
                    name,
                    Object.getOwnPropertyDescriptor(mixin.prototype, name)!,
                );
            }
        });
    });
}

/**
 * Detains a callback for a fixed amount of time. This function is mostly used
 * to extend the time user feedback is visible.
 */
function detainFeedback(callback: () => void) {
    setTimeout(callback, 500);
}

/**
 * Recursively find the first string in an object.
 */
function findFirstString(o: { [index:string]: any } | string): string | undefined {
    if (!o) {
        return;
    }

    if (typeof o === 'string') {
        return o;
    }

    return findFirstString(o[Object.keys(o)[0]]);
}

/**
 * Get all text nodes inside a DOM node.
 */
function getTextNodes(el: Node): Array<Node> {
    const textNodes: Array<Node> = [];

    const treeWalker: TreeWalker = document.createTreeWalker(el, NodeFilter.SHOW_TEXT);

    while(treeWalker.nextNode()) {
        textNodes.push(treeWalker.currentNode);
    };

    return textNodes;
}

/**
 * Array type guard.
 */
const isArray = <T>(maybeArray: T | T[]): maybeArray is T[] => {
    return Array.isArray(maybeArray);
};

/**
 * Encodes the query parameters and adds it to the URL.
 * @param url
 * @param query
 */
function createEncodedURL(url: string, query: {[index:string]: string}): string {
    const encodedQuery = Object.entries(query).map(([key, value]) => {
        return `${key}=${encodeURIComponent(value)}`;
    }).join('&');

    return `${url}?${encodedQuery}`;
}

interface ToastStates {
    loading: BvToastOptions,
    success: BvToastOptions,
    failure: BvToastOptions,
}

/**
 * Awaiting a promise while showing a toast that indicates the promise's states.
 */
async function withToast(promise: Promise<any>, states: ToastStates) {
    const defaultStates: ToastStates = {
        loading: {
            variant: 'dark',
            noAutoHide: true,
            noCloseButton: true,
        },
        success: {
            autoHideDelay: 5 * 1000,
        },
        failure: {
            variant: 'danger',
            autoHideDelay: 5 * 1000,
        },
    };
    const finalStates = merge(defaultStates, states);

    const toast = new BToast({
        propsData: finalStates.loading,
    });
    toast.$mount();
    toast.show();

    try {
        const result = await promise;

        // Override the toast props with success state.
        Object.assign(toast, finalStates.success);

        return result;
    } catch (error) {
        // Override the toast props with failure state.
        Object.assign(toast, finalStates.failure);

        // Rethrow the error.
        throw error;
    } finally {
        // Start to hide the toast.
        toast.noAutoHide = false;
        toast.noCloseButton = false;
        toast.startDismissTimer();
    }
}

/**
 * Check whether the element's top or bottom threshold has been reached or not.
 *
 * @param {Element} el
 * @param {number} offset
 * @param {Boolean} topThreshold
 * @return {Boolean}
 */
function elementThresholdReached(el: Element, offset: number = 50, topThreshold: boolean = false): boolean {
    if (!(el instanceof Element)){
        throw new Error('`el` should be an instance of Element.');
    }

    if (topThreshold) {
        return Boolean(el.scrollTop <= offset);
    }

    const bottomPosition = el.scrollTop + el.clientHeight;

    return Boolean(bottomPosition >= el.scrollHeight - offset);
}

function fetchFileContent(config: AxiosRequestConfig, readMethod: 'readAsArrayBuffer' | 'readAsBinaryString' | 'readAsDataURL' | 'readAsText' = 'readAsDataURL') {
    return new Promise(async(resolve, reject) => {
        try {
            const request = new Request({
                responseType: 'blob',
                ...config,
            });

            const reader = new FileReader();

            const response = await request.send();

            reader.onerror = () => reject(reader.error);

            reader.onload = (event: ProgressEvent<FileReader>) => {
                resolve(event.target?.result);
            };

            invoke(reader, readMethod, response.getData());
        } catch (e) {
            reject(e);
        }
    });
}

/**
 * Finds the first parent element that has set its overflowY property to 'scroll' or 'auto'.
 * Returns document's scrolling element if no parent was found.
 * @param {Element} el
 */
function firstScrollingParent(el: Element): Element {
    const parent = el.parentElement;
    if (parent === null) {
        return document.scrollingElement!;
    }

    const parentStyle = getComputedStyle(parent);

    if (parentStyle.overflowY === 'scroll' || parentStyle.overflowY === 'auto') {
        // Document body somehow always has a scroll top of `0`.
        return parent === document.body
            ? document.scrollingElement!
            : parent;
    }

    return firstScrollingParent(parent);
}

/**
 * Continuously fetch collection until:
 * - The scrolling element is scrollable.
 * - Or the collection can't be fetched anymore (typically because the
 * last page has been reached).
 * - Or the container element is not rendered anymore (typically because page has changed).
 */
async function fetchCollectionUntilScrollable(
    collection: Collection,
    scrollingElement: Element = document.scrollingElement!,
    containerElement: Element,
    offset: number = 100,
): Promise<void> {
    if (!collection.isPaginated()){
        throw new Error('Collection has to be paginated in order to be scrollable.');
    }

    let continueFetching = false;
    let isScrollable = false;

    collection.on('fetch', (event) => {
        // Only continue fetching if 'fetch' event is emitted without no error.
        // That means `collection.onFetchSuccess` is called.
        if (!event.error) {
            continueFetching = true;
        }
    });

    do {
        await Vue.nextTick();

        // Stop fetching if container element is not rendered anymore.
        if (!document.body.contains(containerElement)) {
            return;
        }

        // Set `continueFetching` flag to false. If fetch is successful,
        // it will be set to true by the fetch event listener above.
        continueFetching = false;

        await collection.fetch();

        // Consider the element scrollable if scrollHeight is at least offset (px) taller than clientHeight.
        isScrollable = scrollingElement.scrollHeight - offset > scrollingElement.clientHeight;
    } while (!isScrollable && continueFetching);
}

function stringToVariant(string: String): BVariant {
    if (!string) {
        return 'light';
    }

    const variants: BVariant[] = [
        'success',
        'secondary',
        'warning',
        'danger',
        'dark',
    ];

    let sum = 0;
    for (const char of string) {
        sum += char.charCodeAt(0);
    }

    return variants[sum % variants.length];
}

export {
    applyMixins,
    createEncodedURL,
    detainFeedback,
    elementThresholdReached,
    fetchCollectionUntilScrollable,
    fetchFileContent,
    findFirstString,
    firstScrollingParent,
    getTextNodes,
    isArray,
    stringToVariant,
    withToast,
};
