// Docs: https://bootstrap-vue.js.org/docs/misc/settings/#configuring-defaults

import {
    AlertPlugin,
    BVConfigPlugin,
    BadgePlugin,
    BreadcrumbPlugin,
    ButtonGroupPlugin,
    ButtonPlugin,
    CardPlugin,
    CollapsePlugin,
    DropdownPlugin,
    FormCheckboxPlugin,
    FormFilePlugin,
    FormGroupPlugin,
    FormInputPlugin,
    FormPlugin,
    FormRadioPlugin,
    FormSelectPlugin,
    FormTextareaPlugin,
    ImagePlugin,
    InputGroupPlugin,
    LayoutPlugin,
    LinkPlugin,
    ListGroupPlugin,
    ModalPlugin,
    NavPlugin,
    NavbarPlugin,
    PaginationPlugin,
    PopoverPlugin,
    ProgressPlugin,
    SpinnerPlugin,
    TablePlugin,
    ToastPlugin,
    TooltipPlugin,
    VBScrollspyPlugin,
} from 'bootstrap-vue';
import Vue from 'vue';

export default {
    init() {
        // Supply complete config to the BVConfigPlugin helper plugin.
        Vue.use(BVConfigPlugin, {
            breakpoints: [
                'xs',
                'sm',
                'md',
                'lg',
                'xl',
                'xxl',
                'xxxl',
            ],
            BButton: {
                variant: 'primary',
            },
            BCard: {
                headerTag: 'h4',
                titleTag: 'h5',
            },
            BDropdown: {
                variant: 'white',
            },
            BSpinner: {
                small: true,
            },
            BToast: {
                toaster: 'b-toaster-bottom-right',
                autoHideDelay: 10_000,
                variant: 'dark',
                headerClass: 'py-2',
                // As designed, we will only show the header part of the toast.
                bodyClass: 'd-none',
            },
            BModal: {
                titleTag: 'h3',
            },
        });

        // Then use component plugins.
        Vue.use(AlertPlugin);
        Vue.use(BadgePlugin);
        Vue.use(BreadcrumbPlugin);
        Vue.use(ButtonPlugin);
        Vue.use(ButtonGroupPlugin);
        Vue.use(CardPlugin);
        Vue.use(CollapsePlugin);
        Vue.use(DropdownPlugin);
        Vue.use(FormPlugin);
        Vue.use(FormCheckboxPlugin);
        Vue.use(FormFilePlugin);
        Vue.use(FormGroupPlugin);
        Vue.use(FormInputPlugin);
        Vue.use(FormRadioPlugin);
        Vue.use(FormSelectPlugin);
        Vue.use(FormTextareaPlugin);
        Vue.use(ImagePlugin);
        Vue.use(InputGroupPlugin);
        Vue.use(LayoutPlugin);
        Vue.use(LinkPlugin);
        Vue.use(ListGroupPlugin);
        Vue.use(ModalPlugin);
        Vue.use(NavPlugin);
        Vue.use(NavbarPlugin);
        Vue.use(PaginationPlugin);
        Vue.use(PopoverPlugin);
        Vue.use(ProgressPlugin);
        Vue.use(SpinnerPlugin);
        Vue.use(TablePlugin);
        Vue.use(ToastPlugin);
        Vue.use(TooltipPlugin);
        Vue.use(VBScrollspyPlugin);
    },
};
