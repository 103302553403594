


























import Vue, {VueConstructor} from 'vue';
import {BFormInput} from 'bootstrap-vue';

interface FormGroup extends Vue {
    $refs: {
        input: BFormInput,
    };
}

export default (Vue as VueConstructor<FormGroup>).extend({
    props: {
        error: {
            type: String,
            default: undefined,
        },
        label: {
            type: String,
            default: undefined,
        },
        optional: {
            type: Boolean,
            default: false,
        },
        translationKey: {
            type: String,
            default: undefined,
        },
        placeholder: {
            type: String,
            default: undefined,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        state: {
            type: Boolean,
            default: undefined,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        /**
         * The computed validation state of the input.
         */
        computedState(): boolean | null {
            // If the parent component gives a boolean `state`, return it.
            if (typeof this.state === 'boolean') {
                return this.state;
            }

            // Else, we determine the `state` based on the error.
            return this.error ? false : null;
        },

        /**
         * Returns the label of the form group.
         */
        translatedLabel(): string {
            if (this.label) {
                return this.label;
            } else if (this.translationKey) {
                return this.$t(`inputs.${this.translationKey}.label`);
            }

            return '';
        },

        /**
         * Returns the placeholder of the form group.
         */
        translatedPlaceholder(): string {
            if (this.placeholder) {
                return this.placeholder;
            } else if (this.translationKey) {
                return this.$t(`inputs.${this.translationKey}.placeholder`);
            } else if (this.readonly) {
                return '-';
            }

            return '';
        },
    },

    created() {
        if ((this.$attrs['min'] || this.$attrs['max']) && this.$attrs['type'] === 'number') {
            this.addListener('blur', this.alterNumberValue);
        }
    },

    methods: {
        /**
         * Add the callback function to the event listener
         */
        addListener(event: string, callback: Function) {
            let eventCallbacks: Function[] = [callback];

            // Check if component already has a event listener to append the method to it
            if (this.$listeners[event]) {
                eventCallbacks.push(this.$listeners[event] as Function);
            }

            this.$listeners[event] = eventCallbacks;
        },

        /**
         * Alter the value to be within the min/max bounds
         */
        alterNumberValue(event: Event) {
            let parsedValue = Number(this._.get(event, ['target', 'value']));

            if (Number.isNaN(parsedValue)) {
                this.$emit('input', 0);

                return;
            }

            if (this.$attrs['min']) {
                parsedValue = Math.max(parsedValue, Number(this.$attrs['min']));
            }

            if (this.$attrs['max']) {
                parsedValue = Math.min(parsedValue, Number(this.$attrs['max']));
            }

            this.$emit('input', parsedValue);
        },

        focus() {
            this.$refs.input.focus();
        },
    },
});
