import * as Sentry from '@sentry/vue';
import Vue from 'vue';
import VueI18Next from '@panter/vue-i18next';
import i18next from 'i18next';

Vue.use(VueI18Next);

const LANGUAGE = 'en-GB';
const LANGUAGE_SHORT = 'en';

i18next.init({
    lng: 'en-GB', // Force the app to be in en-GB.
    resources: {
        en: {
            translation: require('@/translations/en.json'),
        },
    },
    saveMissing: true,
    missingKeyHandler: (lngs: readonly string[], namespace: string, key: string): void => {
        const message = `Missing translation for key: ${key}`;

        console.warn(message);

        Sentry.captureMessage(message, 'warning');
    },
});

export default new VueI18Next(i18next);

export {LANGUAGE, LANGUAGE_SHORT};
