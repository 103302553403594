import ButtonBack from '@/components/common/buttons/ButtonBack.vue';
import ButtonIcon from '@/components/common/buttons/ButtonIcon.vue';
import ButtonResource from '@/components/common/buttons/ButtonResource.vue';
import ButtonState from '@/components/common/buttons/ButtonState.vue';
import FormGroup from '@/components/common/inputs/FormGroup.vue';
import FormatNumber from '@/components/common/FormatNumber.vue';
import InputDropdown from '@/components/common/inputs/InputDropdown.vue';
import OwThumbnail from '@/components/common/OwThumbnail.vue';
import SpinnerBlock from '@/components/common/SpinnerBlock.vue';
import Vue from 'vue';
import WaitForResource from '@/components/common/WaitForResource.vue';

export default {
    init() {
        Vue.component('BtnBack', ButtonBack);
        Vue.component('BtnIcon', ButtonIcon);
        Vue.component('BtnState', ButtonState);
        Vue.component('BtnResource', ButtonResource);

        Vue.component('FormGroup', FormGroup);
        Vue.component('FormatNumber', FormatNumber);
        Vue.component('InputDd', InputDropdown);
        Vue.component('OwThumbnail', OwThumbnail);
        Vue.component('SpinnerBlock', SpinnerBlock);
        Vue.component('WaitForResource', WaitForResource);
    },
};
