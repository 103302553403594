import Collection from '@/models/vue-mc/cms/Collection';
import Model from '@/models/vue-mc/cms/Model';

class Role extends Model {
    id!: number;
    name!: string;
}

class Roles extends Collection {
    options() {
        return {
            model: Role,
        };
    }
}

export {Role, Roles};

export default Role;
