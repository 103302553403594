














import Collection from '@/models/vue-mc/Collection';
import Model from '@/models/vue-mc/Model';
import Vue from 'vue';

export default Vue.extend({
    props: {
        /**
         * In addition to Collection and Model, a simple object can also be sent.
         */
        resource: {
            type: [Collection, Model, Object],
            required: true,
            validator: (resource: Record<string, unknown>) => typeof resource.saving === 'boolean'
                && typeof resource.fatal === 'boolean',
        },
        disabled: {
            type: Boolean,
            default: undefined,
        },
        /**
         * Indicates if this button should show loader when deleting or saving (default).
         */
        toDelete: {
            type: Boolean,
            default: false,
        },
        /**
         * If true, when loading, error, or success, only the state icons will be shown
         * and slot content will be hidden.
         */
        stateIconsOnly: {
            type: Boolean,
            default: false,
        },
        icon: {
            type: String,
            default: '',
        },
        enableOnTouched: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            hasLoaded: false,
        };
    },

    computed: {
        loading(): boolean {
            return this.toDelete ? this.resource.deleting : this.resource.saving;
        },

        error(): boolean {
            return this.resource.hasErrors || this.resource.fatal;
        },

        success(): boolean {
            return this.hasLoaded && !this.loading && !this.error;
        },
        /**
         * If `:disabled` prop is not provided, we will disable this button
         * if the resource is not changed. The disabled state should apply
         * after the success feedback is hidden.
         */
        cDisabled(): boolean {
            if (this.disabled !== undefined) {
                return this.disabled;
            }

            if (this.toDelete) {
                return false;
            }

            return (this.enableOnTouched
                ? !this.resource.touched
                : !this.resource.changed()
            ) && !this.success;
        },
    },

    watch: {
        loading: function(newVal, oldVal) {
            this.hasLoaded = Boolean(oldVal === true && newVal === false);

            // Reset `hasLoaded` back to false after 1.5 second. This will also
            // reset `success` and hide the check icon.
            if (this.hasLoaded) {
                setTimeout(() => this.hasLoaded = false, 1500);
            }
        },
    },
});
