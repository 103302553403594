


















import StateIcon from '@/components/common/StateIcon.vue';
import Vue from 'vue';

export default Vue.extend({
    components: {
        StateIcon,
    },

    props: {
        icon: {
            type: String,
            default: '',
        },
        iconClass: {
            type: [String, Object, Array],
            default: '',
        },
    },
});
