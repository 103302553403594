import Vuex, {Store} from 'vuex';
import Vue from 'vue';
import auth from '@/store/modules/Auth';
import cache from '@/store/modules/Cache';
import counts from '@/store/modules/Counts';

export interface RootState {
    auth: auth,
    cache: cache,
    counts: counts
}

Vue.use(Vuex);

const store: Store<RootState> = new Vuex.Store({
    modules: {
        auth,
        cache,
        counts,
    },
});

// We put the current user instance as a global Vue property,
// so every component can access it easily.
Object.defineProperty(Vue.prototype, '$me', {
    get() {
        return store.state.auth.me;
    },
});

export default store;
