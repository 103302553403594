import {PluginObject} from 'vue';
import RequestError from 'vue-mc/src/Errors/RequestError';
import ValidationError from 'vue-mc/src/Errors/ValidationError';
import axios from 'axios';

const errorHandler: PluginObject<object> = {
    install(Vue) {
        Vue.config.errorHandler = function(error) {
            // Catch vue-mc Model validation error.
            if (error instanceof ValidationError) {
                return;
            }

            if (error instanceof RequestError) {
                // Catch 422 responses that are made by vue-mc requests.
                if (error.getResponse().getStatus() === 422) {
                    return;
                }

                // Catch axios cancel errors that are thrown when vue-mc
                // requests are cancelled.
                if (axios.isCancel(error.getError())) {
                    return;
                }
            }

            throw error;
        };
    },
};

export default errorHandler;
