import * as Sentry from '@sentry/vue';
import Vue from 'vue';

if (process.env.VUE_APP_SENTRY_DNS) {
    Sentry.init({
        dsn: process.env.VUE_APP_SENTRY_DNS,
        environment: process.env.NODE_ENV,
        Vue,
    });
}
