import {Route, RouteConfig} from 'vue-router';
import {applySidebarParent, prefixRoutes} from '@/router/helpers';

const routes: RouteConfig[] = prefixRoutes('agreements', [
    {
        path: '/',
        name: 'agreements.index',
        meta: {
            permissions: 'read:companies:agreements',
        },
        component: () => import('@/pages/agreements/Index.vue'),
    },
    {
        path: ':id',
        name: 'agreements.show',
        meta: {
            permissions: 'read:companies:agreements',
        },
        props: (route: Route) => ({
            agreementId: Number.parseInt(route.params.id),
        }),
        component: () => import('@/pages/agreements/Show.vue'),
    },
]);

applySidebarParent('agreements.index', routes);

export default routes;
