
















import {Component, Prop, Vue} from 'vue-property-decorator';
import {RawLocation} from 'vue-router';

@Component
export default class ButtonBack extends Vue {
    @Prop({type: Object, required: false, default: null})
        to!: RawLocation | null;

    back() {
        if (this.to == null) {
            this.$router.go(-1);
        } else {
            this.$router.push(this.to);
        }
    }
};
