
























































































import Vue, {VueConstructor} from 'vue';
import {BDropdown} from 'bootstrap-vue';
import Model from '@/models/vue-mc/Model';
import {getTextNodes} from '@/library/helpers';
import i18next from 'i18next';

interface Item {
    [key: string]: any
}

interface DataType {
    searchQuery: string;
    searchTimeout: number | null;
}

interface InputDropdown extends Vue {
    $refs: {
        'b-dropdown': BDropdown,
        'menu-wrapper': HTMLElement,
        'items': HTMLElement[],
    }
}

export default (Vue as VueConstructor<InputDropdown>).extend({
    props: {
        items: {
            type: Array,
            default: () => [],
        },
        placeholder: {
            type: String,
            default: () => i18next.t('inputs.dropdown.placeholder'),
        },
        value: {
            type: [String, Number, Array, Object],
            default: '',
        },
        labelKey: {
            type: String,
            default: 'label',
        },
        valueKey: {
            type: String,
            default: 'value',
        },
        itemClass: {
            type: [String, Array, Object],
            default: '',
        },
        iconClass: {
            type: [String, Array, Object],
            default: '',
        },
        state: {
            type: Boolean,
            default: null,
        },
        labelForNull: {
            type: String,
            default: '',
        },
        deselectable: {
            type: Boolean,
            default: false,
        },
        fixedWidth: {
            type: Boolean,
            default: false,
        },
    },

    data(): DataType {
        return {
            searchQuery: '',
            searchTimeout: null,
        };
    },

    computed: {
        selected(): Item | null {
            return this.dropdownItems.find(item => {
                const value = this.getValue(item);

                if (value instanceof Model && this.value instanceof Model) {
                    return value.identifier() === this.value.identifier();
                }

                return this.getValue(item) === this.value;
            }) || null;
        },

        toggleClass(): object {
            return {
                'border-danger': this.state === false,
                'text-secondary': this.selected === undefined, // When nothing selected, placeholder should be gray.
            };
        },

        dropdownItems(): Array<Item> {
            // Use the spread operator to copy this.items without reference.
            const items = [...this.items] as Item[];

            if (
                this.deselectable
                && this.value
                && !items.find((item: Item) => this.getValue(item) === this.value)
            ) {
                items.unshift({
                    [this.valueKey]: this.value,
                    [this.labelKey]: this.value,
                });
            }

            if (this.labelForNull) {
                items.unshift({
                    [this.valueKey]: null,
                    [this.labelKey]: this.labelForNull,
                });
            }

            return items;
        },
    },

    methods: {
        closeDropdown() {
            this.$refs['b-dropdown']?.hide();
        },

        equals(a: any, b: any) {
            if (a instanceof Model && b instanceof Model) {
                return a.identifier() == b.identifier();
            }

            return this._.isEqual(a, b);
        },

        getValue(item: any): any {
            if (this.valueKey === '') {
                return item;
            }

            return this._.get(item, this.valueKey, item);
        },

        loadMore() {
            this.$emit('menu-bottom');
        },

        /**
         * Set the menu wrapper on focus.
         */
        setFocusToMenuWrapper() {
            this.$refs['menu-wrapper'].focus();
            this.$emit('shown');
        },

        /**
         * Set focus to the matching item as the user presses a key.
         */
        search(e: KeyboardEvent) {
            // No alphanumeric key was pressed.
            if (e.key.length > 1) {
                return;
            }

            // Cancel previous search query reset.
            clearTimeout(this.searchTimeout!);

            // Schedule to reset search query after certain period of inactivity.
            this.searchTimeout = window.setTimeout(() => {
                this.searchQuery = '';
            }, 500);

            this.searchQuery += e.key;

            // Find the first item which contains text that matches the query.
            const matchingItem = this.$refs['items'].find(i => {
                // Get all text nodes inside the item.
                const nodes = getTextNodes(i.firstChild!);

                // Prepare and collect the text of each node into an array.
                const texts = nodes.map(
                    n => n.textContent!.toLowerCase().replace(/[^0-9a-z\ ]/g, '').trim(),
                );

                // Return true if one of the text starts with the query.
                return texts.some(t => t.startsWith(this.searchQuery));
            }, this);

            if (!matchingItem) {
                return;
            }

            // Focus on the `.dropdown-item` (first child of the item).
            (matchingItem.firstChild as HTMLElement).focus();
        },

        /**
         * Emits the inputted value to the parent.
         */
        input(item: Item) {
            this.closeDropdown();

            // Only emits `input` if different value is selected.
            if (item !== this.selected) {
                return this.$emit('input', this.getValue(item));
            }

            if (this.deselectable) {
                this.$emit('input', this.getValue(null));
            }
        },
    },
});
