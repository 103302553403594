import {RouteConfig} from 'vue-router';
import agreementRoutes from '@/router/routes/agreements';
import auth from '@/router/middleware/auth';
import authRoutes from '@/router/routes/auth';
import invoicesRoutes from '@/router/routes/invoices';
import ordersRoutes from '@/router/routes/orders';
import permission from '@/router/middleware/permission';
import profileRoutes from '@/router/routes/profile';
import stockRoutes from '@/router/routes/stock';

// @ts-ignore
const routes: RouteConfig[] = [
    ...authRoutes,
    {
        path: '/',
        component: () => import('@/components/layouts/Base.vue'),
        meta: {
            middleware: [auth, permission],
        },
        children: [
            {
                path: '/',
                name: 'home',
                component: () => import('@/pages/Home.vue'),
            },
            {
                path: 'how-it-works/:path',
                name: 'how-it-works',
                props: true,
                component: () => import('@/pages/how-it-works/Index.vue'),
            },
            ...agreementRoutes,
            ...invoicesRoutes,
            ...ordersRoutes,
            ...profileRoutes,
            ...stockRoutes,
            {
                path: '*',
                name: '404',
                component: () => import('@/pages/404.vue'),
            },
        ],
    },
];

// Add the styleguide route when environment is not production.
if (process.env.NODE_ENV !== 'production') {
    routes.push({
        path: '/styleguide',
        component: () => import('@/pages/styleguide/Index.vue'),
    });
}

export default routes;
