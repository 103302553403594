import {applySidebarParent, prefixRoutes} from '@/router/helpers';
import {RouteConfig} from 'vue-router';

const routes: RouteConfig[] = prefixRoutes('stock', [
    {
        path: '/',
        name: 'stock.index',
        meta: {
            permissions: 'read:companies:products',
        },
        component: () => import('@/pages/stock/Index.vue'),
    },
]);

applySidebarParent('stock.index', routes);

export default routes;
