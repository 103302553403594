import Vue, {PluginObject} from 'vue';
import {BvToastOptions} from 'bootstrap-vue';
import {v4} from 'uuid';

interface ToastPluginOptions extends BvToastOptions {
    body: string;
}

type ToastFn = (options: BvToastOptions) => string;

const iconByVariant = (variant: string): string => {
    switch (variant) {
        case 'primary':
            return 'info-circle';
        case 'success':
            return 'check-circle';
        case 'warning':
            return 'exclamation-triangle';
        case 'danger':
            return 'exclamation-rhombus';
        default:
            return '';
    }
};

const toast = function(this: Vue, data: ToastPluginOptions): string {
    const id = v4();

    const options = {
        noAutoHide: false,
        closeable: true,
        ...data,
    };

    const create = this.$createElement;

    const body = data.to
        ? create ('router-link', {props: {to: {name: data.to}}, class: 'text-white'}, data.body)
        : create ('div', {class: 'd-inline'}, data.body);

    const close = options.closeable
        ? create (
            'b-button',
            {
                class: 'ml-auto d-flex align-items-center',
                on: {click: () => this.$bvToast.hide(id)},
            },
            [
                create ('fa', {props: {icon: 'cross'}}),
            ],
        )
        : null;

    const icon = data.variant
        ? create ('fa', {props: {icon: iconByVariant(data.variant)}, class: 'mx-3 lead'})
        : null;

    const vNodesTitle = create (
        'div', {class: 'w-100 d-flex align-middle'}, [
            create (
                'div', {class: 'icon d-flex align-items-center'}, [icon],
            ),
            create (
                'div',
                {class: 'icon'}, [body],
            ),
            close,
        ],
    );

    this.$bvToast.toast([], {
        title: [vNodesTitle],
        id,
        toaster: 'b-toaster-top-center',
        solid: true,
        noCloseButton: true,
        ...data,
    });

    return id;
};

const ToastPlugin: PluginObject<object> = {
    install(vue: typeof Vue) {
        vue.prototype.$toast = toast;
    },
};

export default ToastPlugin;

export {ToastFn};
