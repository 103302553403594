import Component1 from 'vue-class-component';
import {Component as Component2} from 'vue-property-decorator';

/**
 * Register vue-router hooks to be usable in the class components.
 * Unfortunately this has to be done like this due to this issue:
 * https://github.com/kaorun343/vue-property-decorator/issues/259#issuecomment-618705738
 */

Component1.registerHooks([
    'beforeRouteEnter',
    'beforeRouteLeave',
    'beforeRouteUpdate',
]);

Component2.registerHooks([
    'beforeRouteEnter',
    'beforeRouteLeave',
    'beforeRouteUpdate',
]);
