import {RouteConfig} from 'vue-router';
import {set} from 'lodash';

function prefixRoutes(prefix: string, routes: RouteConfig[]) {
    return routes.map(route => {
        route.path = prefix + '/' + route.path;
        return route;
    });
}

function applySidebarParent(sidebarName: string, routes: RouteConfig[]) {
    return routes.map(route => {
        set(route, 'meta.sidebarName', sidebarName);

        if (route.children) {
            applySidebarParent(sidebarName, route.children);
        }

        return route;
    });
}

export {
    prefixRoutes,
    applySidebarParent,
};
