import Enumeration, {Enum} from '@/library/enumerations/Enumeration';

/**
 * Source: https://github.com/owowagency/steel-solutions-api/blob/develop/app/Library/Enumerations/CompanyType.php
 */
@Enum
class CompanyType extends Enumeration {
    static STEEL_PRODUCER = 0;
    static STEEL_TRADER = 1;
    static STEEL_SERVICE_CENTER = 2;
    static STOCKIST = 3;
    static TOOLS_AND_MACHINERY_MANUFACTURER = 4;
    static PACKAGING_MANUFACTURER = 5;
    static ENERGY_MANUFACTURER = 6;
    static TRANSPORT_MANUFACTURER = 7;
    static AUTOMOTIVE_MANUFACTURER = 8;
    static BUILDINGS_AND_INFRASTRUCTURE_MANUFACTURER = 9;
    static APPLIANCES_MANUFACTURER = 10;
    static TRANSPORT = 11;
    static LOGISTICS_AND_WAREHOUSING = 12;

    /**
     * The key used to retrieve translations for the enumeration keys.
     */
    static translationKey = 'companyType';
}

export default CompanyType;
