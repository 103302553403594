




import Component from 'vue-class-component';
import Vue from 'vue';
import hotkeys from 'hotkeys-js';

@Component
export default class App extends Vue {
    created() {
        const keyPaths = [
            {
                key: 'u',
                path: 'profile.team',
                permissions: 'create:companies:users',
            },
            {
                key: 't',
                path: 'orders.create.transport',
                permissions: 'create:orders',
            },
            {
                key: 'i',
                path: 'orders.create.inbound',
                permissions: 'create:orders',
            },
            {
                key: 'o',
                path: 'orders.create.outbound',
                permissions: 'create:orders',
            },
            {
                key: 'd',
                path: 'orders.create.direct-delivery',
                permissions: 'create:orders',
            },
        ];

        const shortcutKeys = keyPaths.map(item => item.key).join(',');

        hotkeys(shortcutKeys, (_, handler) => {
            const item = keyPaths.find(item => item.key === handler.key);

            if (item && this.$me && this.$me.canAny(item.permissions) && this.$route.name != item.path) {
                switch (item.key) {
                    case 't':
                        document.getElementById('button-create-transport')?.click();
                        break;
                    case 'i':
                        document.getElementById('button-create-inbound')?.click();
                        break;
                    case 'o':
                        document.getElementById('button-create-outbound')?.click();
                        break;
                    case 'd':
                        document.getElementById('button-create-direct-delivery')?.click();
                        break;
                    default:
                        this.$router.push({name: item.path});
                }
            }
        });
    }

    mounted() {
        if (process.env.VUE_APP_JIRA_COLLECTOR_ID) {
            const feedbackScript = document.createElement('script');

            feedbackScript.setAttribute('src', `https://owowagency.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/1jmxwi/b/8/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=${process.env.VUE_APP_JIRA_COLLECTOR_ID}`);

            document.head.appendChild(feedbackScript);
        }
    }

    beforeDestroy() {
        hotkeys.unbind();
    }
};
