import i18next from 'i18next';

interface EnumConstructor {
    new(): Enumeration;
    enumeration: Record<string, number>;
}

/**
 * The structure of the object returned by `static object()` method.
 */
interface EnumObject {
    key: string | null;
    value: number | null;
    translation: string;
}

/**
 * The allowed types to be used to find an enumeration.
 */
type EnumTarget = number | string | null;

/**
 * A decorator to auto-fill Enumeration.enumeration.
 */
function Enum(constructor: EnumConstructor) {
    constructor.enumeration = {};

    for (const [key, value] of Object.entries(constructor)) {
        // Only allow uppercase keys with a numeric value.
        if (
            key.toUpperCase() === key
            && typeof value === 'number'
            && !Number.isNaN(value)
        ) {
            constructor.enumeration[key] = value;
        }
    }
}

export {
    Enum,
    EnumObject,
    EnumTarget,
};

export default class Enumeration {
    /**
     * An iterator for the values of the enumeration.
     */
    static [Symbol.iterator](): IterableIterator<number> {
        return this.values.values();
    }

    /**
     * The enumeration object that holds the keys and values.
     */
    static enumeration: Record<string, number> = {};

    /**
     * The key used to retrieve translations for the enumeration keys.
     */
    static translationKey: string = '';

    /**
     * Returns the keys of the enumeration.
     */
    static get keys(): string[] {
        return Object.keys(this.enumeration);
    }

    /**
     * Returns object structures for every value in the enumeration.
     */
    static get objects(): EnumObject[] {
        return this.keys.map((k) => {
            return this.object(k);
        });
    }

    /**
     * Returns the values of the enumeration.
     */
    static get values(): number[] {
        return Object.values(this.enumeration);
    }

    /**
     * Returns the key for the specified target.
     */
    static key(target: EnumTarget): string | null {
        if (target === undefined) {
            return null;
        }

        return this.keys.find(key => {
            if (typeof target === 'string') {
                return key === target;
            }

            return this.enumeration[key] === target;
        }) || null;
    }

    /**
     * Returns the object for the specified target.
     */
    static object(target: EnumTarget): EnumObject {
        const key = this.key(target);

        return {
            key,
            value: this.value(key),
            translation: this.translateKey(key),
        };
    }

    /**
     * Returns the value for the specified target.
     */
    static value(target: EnumTarget): number | null {
        if (target === null) {
            return null;
        }

        const result = this.values.find(value => {
            if (typeof target === 'number') {
                return value === target;
            }

            return this.enumeration[target] === value;
        });

        return typeof result === 'undefined' ? null : result;
    }

    /**
     * Returns the translation for the specified target.
     */
    static translate(target: EnumTarget): string {
        return typeof target === 'string'
            ? this.translateKey(target)
            : this.translateValue(target);
    }

    /**
     * Returns the translation for the specified key.
     */
    static translateKey(key: string | null): string {
        return i18next.t(`enums.${this.translationKey}.${key}`);
    }

    /**
     * Returns the translation for the specified value.
     */
    static translateValue(value: number | null): string {
        const key = this.key(value);

        return this.translateKey(key);
    }
}
