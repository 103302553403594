import '@/library/sentry';
import '@/library/registerComponentHooks';
import 'url-search-params-polyfill';
import App from '@/App.vue';
import FormatPlugin from '@/library/plugins/Format';
import PortalVue from 'portal-vue';
import ToastPlugin from '@/library/plugins/Toast';
import Vue from 'vue';
import VueI18Next from '@/translations/VueI18Next';
import VueUnits from 'vue-units';
import bootstrapVue from '@/library/bootstrapVue';
import components from '@/components/common';
import config from '@/library/data/config.json';
import fontAwesome from '@/library/fontAwesome';
import globalErrorHandler from '@/library/globalErrorHandler';
import infiniteScroll from '@/library/directives/infiniteScroll';
import moment from 'moment';
import router from '@/router';
import searchable from '@/library/directives/searchable';
import store from '@/store';
import vueLodash from '@/library/vueLodash';

Vue.config.productionTip = false;

Vue.directive('infinite-scroll', infiniteScroll);
Vue.directive('searchable', searchable);
Vue.use(vueLodash);
Vue.use(globalErrorHandler);
Vue.use(FormatPlugin);
Vue.use(ToastPlugin);
Vue.use(VueUnits);

Vue.use(PortalVue);
fontAwesome.init();
bootstrapVue.init();
components.init();

Vue.prototype.$config = config;

moment.defaultFormat = 'YYYY-MM-DD';
Vue.use(require('vue-moment'), {moment});

new Vue({
    router,
    store,
    i18n: VueI18Next,
    render: (h) => h(App),
}).$mount('#app');
